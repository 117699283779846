import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { PersonalService } from 'src/app/services/aih/personal-api.service';
import { Store, select } from '@ngrx/store';
import { Reducers } from 'src/app/reducers';
import { Patient } from 'src/app/models/aih/Patient.model';
import { environment } from 'src/environments/environment';
import * as _ from "lodash";
import * as moment from "moment";
import { Subscription } from 'rxjs';
import { updatePatient } from 'src/app/reducers/Patient';
import Auth from 'src/app/models/Authenticate';

@Component({
  selector: 'app-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.scss']
})
export class PatientFormComponent implements OnInit, OnDestroy {

  isLoading = false;
  isSubmiting = false;

  patientInfomation = new FormGroup({
    patient_name: new FormControl({ value: "", disabled: true }),
    patient_name_e: new FormControl({ value: "", disabled: true }),
    date_of_birth: new FormControl({ value: "", disabled: true }),
    gender: new FormControl({ value: "", disabled: true }),
    phone_number: new FormControl({ value: "", disabled: true }),
    email: new FormControl({ value: "", disabled: true }),
    address: new FormControl({ value: "", disabled: true }),
    company: new FormControl({ value: "", disabled: true }),
    date_of_exam: new FormControl({ value: "" }),
    contact_person: new FormControl({ value: "", disabled: true }),
    contact_relationship: new FormControl({ value: "", disabled: true }),
    contact_phone_number: new FormControl({ value: "", disabled: true }),
    cso: new FormControl(""),
  });

  constructor(
    @Inject(TranslateService) public translate: TranslateService,
    @Inject(PersonalService) public personalService: PersonalService,
    public store: Store<Reducers>
  ) { }
  auth: Auth;
  patient: Patient;
  subscire_1: Subscription;
  subscire_2: Subscription;

  ngOnInit() {
    this.subscire_1 = this.store.pipe(select(state => ({
      auth: state.auth,
      history: state.history
    }))).subscribe(({ auth, history }) => {
      if (!this.patient) {
        if (!this.patientInfomation.controls["email"].value) {
          if (auth && history) {
            this.auth = auth;
            return this.getProfile(auth.user_username, history.patient_visit_id);
          }
        }
      }
    });
    this.subscire_2 = this.store.pipe(select(state => ({
      patient: state.patient
    }))).subscribe(({ patient }) => {
      if (patient && patient.patient_visit_id) {
        this.addPatient(patient);
      }
    });
  }

  ngOnDestroy() {
    this.subscire_1.unsubscribe();
    this.subscire_2.unsubscribe();
  }

  autoSavePatient() {
    this.store.dispatch(updatePatient(this.patientInfomation.value));
  }

  getProfile(username: string, pid: string) {
    if (this.isLoading) {
      return;
    }
    const data = {
      pid,
      "username": username,
      "key": environment.key
    };
    this.isLoading = true;
    this.personalService.getUserProfile(data.pid, data.key, data.username).subscribe((profile: Patient) => {
      console.log(profile);
      if (profile && _.isObject(profile)) {
        // if(!profile.contact_person){
        //   delete profile.contact_person;
        // }
        if (this.patient && this.patient.date_of_exam) {
          delete profile.date_of_exam;
        }
        delete profile.cso;
        this.store.dispatch(updatePatient(profile));
      } else {
        const { user_fullname, user_email, user_phone } = this.auth;
        const data: any = {
          patient_name: user_fullname,
          email: user_email,
          phone_number: user_phone
        };
        this.store.dispatch(updatePatient(data));
      }
      this.isLoading = false;
    });
  }

  addPatient(profile: Patient) {
    if (profile.date_of_exam) {
      const time = moment(profile.date_of_exam);
      if (time.isValid()) {
        profile.date_of_exam = time.format("YYYY-MM-DD");
      }
    }
    this.patient = profile;
    this.patientInfomation.patchValue(profile);

  }

  getDateOfBirth() {
    const value = this.patientInfomation.controls["date_of_birth"].value;
    return value ? moment(value).format("DD-MM-YYYY") : "";
  }

  onSubmit(form: any) {
    this.isSubmiting = true;
    setTimeout(() => {
      this.isSubmiting = false;
      this.translate.get("Success").subscribe(value => {
        Swal.fire(value, "", "success");
      });
    }, 1000);
  }

  handleChangeGender(e: any) {
    const { checked, value } = e.target;
    if (checked) {
      this.patientInfomation.controls['gender'].setValue(value);
    }
  }

}
