import { HistoryExamed } from '../models/HistoryExamed';
import { Action } from '@ngrx/store';

export const ADD_HISTORY = "ADD_HISTORY";
export const DEL_HISTORY = "DEL_HISTORY";

export const addHistoryExamed = (data: HistoryExamed[]) => ({type: ADD_HISTORY, data });
export const removeHistoryExamed = () => ({type: DEL_HISTORY});

export interface HistoryExamedAction extends Action {
    data: HistoryExamed[];
}

export function historyExamedReducer(state = [], action: HistoryExamedAction){
    switch(action.type){
        case ADD_HISTORY:
            return action.data;
        case DEL_HISTORY:
            return [];
        default:
            return state;
    }
}
