import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Reducers } from "src/app/reducers";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin } from "rxjs";
import { PersonalService } from "src/app/services/aih/personal-api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  isValid = true;
  isLoading = false;
  isClicked = false;
  isAuthSuccess = false;
  @Output() onClickRememberMe = new EventEmitter();

  constructor(
    protected store: Store<Reducers>,
    @Inject(Router) protected router: Router,
    private translate: TranslateService,
    public personalService: PersonalService
  ) { }

  ngOnInit() { }

  onCancelBaoMat() {
    this.router.navigate(["/"]);
  }

  onSubmit(form: NgForm) {
    if (this.isAuthSuccess) {
      return forkJoin([
        this.translate.get("This phone number is already registered"),
      ]).subscribe(([Success]: string[]) => {
        Swal.fire(Success);
      });
    }
    // Số điện thoại này đã được đăng ký./
    this.isValid = form.valid;
    // check form valid
    if (!this.isValid) {
      return;
    }
    this.isLoading = true;
    const { account } = form.value;
    this.personalService
      .createUser(account, environment.key)
      .subscribe((result: any) => {
        if (result === "Error") {
          this.authErrors();
        } else if (result === "Exist") {
          this.authExists();
        } else {
          this.authSuccess(result);
        }
        this.isLoading = false;
      });
  }

  authExists() {
    forkJoin([
      this.translate.get('This phone number is already registered'),
      this.translate.get('text_close'),
    ]).subscribe(([title, cancel]: string[]) => {
      Swal.fire({ title, text: "", cancelButtonText: cancel });
    });
  }

  authSuccess(password: string) {
    forkJoin([
      this.translate.get("Success"),
      this.translate.get(
        "Your registration has been successful. Your password is"
      ),
    ]).subscribe(([Success, txt]: string[]) => {
      Swal.fire(Success, `${txt}: ${password}`, "success");
      this.router.navigate(["/"]);
      this.isClicked = true;
      this.isAuthSuccess = true;
    });
  }

  authErrors() {
    forkJoin([
      this.translate.get("register_error_phone"),
      this.translate.get("This phone number is already registered"),
      this.translate.get("text_close"),
    ]).subscribe(([title, text, cancel]: string[]) => {
      text = this.isClicked ? text : "";
      Swal.fire({ title, text: "", cancelButtonText: cancel });
    });
  }
}
