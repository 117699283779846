import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  NgbDateStruct,
  NgbCalendar,
  NgbDate,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { momentToNgbDate, stringPadStart, maxMomentToNgbDate } from 'src/app/utilities';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {

  selectedDate: NgbDateStruct;
  expanded = false;

  @Input() placeholder: string = "yyyy-mm-dd";
  @Input() doctorSchedule: any;
  @Input() animateAfter: boolean;
  @Input() date: string;
  @Input() disabled: boolean;
  @Output() changeDate = new EventEmitter<NgbDateStruct>();
  @Input() min: NgbDateStruct | null = null;
  @Input() max: NgbDateStruct | null = null;
  @Input() size: string = "";
  @Input() icon: string = "date";
  @Input() position: "left" | "right" | "center" = "left";
  public isDisabled: any;

  public disableDays = [];

  constructor(private calendar: NgbCalendar) {}

  ngOnInit(): void {
    this.reset();
  }

  toggleExpandDate(isReset: boolean = false) {
    if(this.disabled){
      return;
    }
    if(this.icon === 'arrow' && isReset){
      if(this.selectedDate){
        return this.reset();
      }
    }
    this.expanded = !this.expanded;
  }

  expandDate(expand: boolean) {
    this.expanded = expand;
  }

  onDateSelect(date: NgbDateStruct) {
    this.expanded = false;
    this.selectedDate = date;
    this.changeDate.emit(date);
  }

  onClickOutSide(e) {
    if(this.expanded){
      this.expanded = false;
    }
  }

  getDateString() {
    const date = this.selectedDate;
    return date
      ? `${stringPadStart(String(date.day), 2, '0')}-${stringPadStart(
          String(date.month),
          2,
          '0',
        )}-${date.year}`
      : '';
  }

  getMinDate(): NgbDateStruct {
    return this.min;
  }

  getMaxDate(): NgbDateStruct {
      return this.max;
  }

  getMarkDisableDate() {
    return (date: NgbDate) => {
      const rs = this.disableDays.find(item => {
        return item.year === date.year && item.month === date.month && item.day === date.day;
      });
      return this.calendar.getWeekday(date) > 6 || rs;
    };
  }

  handleDisableDays(offs) {
    this.disableDays = offs.map(item => {
      return momentToNgbDate(item);
    });
  }

  reset() {
    this.changeDate.emit(null);
    this.selectedDate = null;
    // let defaultDate = moment();
    // If Sunday then choose next day => Monday
    // if (defaultDate.day() === 0) {
    //   defaultDate = defaultDate.add(1, 'days');
    // }

    // this.onDateSelect(momentToNgbDate(defaultDate));
  }

}
