import { Injectable } from "@angular/core";
import { AihApiService } from './rest-api.service';

export interface overViewCounterArgs {
    period: string;
    fromDate?: string;
    toDate?: string;
    key: string;
}

export interface AppointMentArgs {
    page: number,
    pageSize: number,
    filter: string,
    key: string;
    period: string;
    fromDate: string;
    toDate: string;

}

@Injectable()
export class CompanyService extends AihApiService {
    /**
     * Lấy Period của công ty
     * @param username string
     * @param key string
     */
    getListPackageByUser(username: string, key: string) {
        return this.post("/api/hccout/getlistpackagebyuser", { username, key });
    }

    getOverviewCounter(args: overViewCounterArgs) {
        return this.post("/api/hccout/overviewcounter", args);
    }

    getListAppointment(args: AppointMentArgs) {
        return this.post("/api/hccout/getlistappointment", args);
    }

    /**
     * Đặt lịch
     * @param data
     */
    setAppointment(data: any){
        return this.post("/api/hccout/setAppointment", data);
    }

    uploadApointment(file: File){
        return this.upload("/api/hccout/importUpdateHCC", file);
    }
    
    exportAppointment(key: string, fromDate: string, toDate: string, package_code: string){
        const data = {key, fromDate, toDate, package_code};
        return this.post("/api/hccout/exportListHCC", data);
    }
}
