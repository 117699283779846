import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Reducers } from 'src/app/reducers';
import Auth from 'src/app/models/Authenticate';
import { CompanyService } from 'src/app/services/aih/company-api.service';
import { Subscription } from 'rxjs';
import { Period } from 'src/app/reducers/Period';
import { environment } from 'src/environments/environment';
import * as moment from "moment";

@Component({
  selector: 'app-overviews',
  templateUrl: './overviews.component.html',
  styleUrls: ['./overviews.component.scss']
})
export class OverviewsComponent implements OnInit, OnDestroy {
  unSubscire: Subscription;
  unSubscirePeriod: Subscription;

  isLoading = false;
  overviews = {
    Total_Booked: 0,
    Total_Completed: 0,
    Total_Examed: 0,
    Total_Incompleted: 0,
    Total_NotBooked: 0,
    Total_NotExamed: 0,
    Total_Patient: 0
  };

  options = {
    startVal: 0,
    useGrouping: true,
    useEasing: true,
    duration: 5
  };

  constructor(
    @Inject(Router) protected router: Router,
    @Inject(Store) protected store: Store<Reducers>,
    protected companyService: CompanyService
  ) { }

  ngOnDestroy() {
    this.unSubscire.unsubscribe();
    this.unSubscirePeriod.unsubscribe();
  }

  ngOnInit() {
    this.unSubscire = this.store.pipe(select("auth")).subscribe((auth: Auth) => {
      if(auth){
        const { user_type } = auth;
        if (user_type === 3) {
          this.router.navigate(["/dashboard/confirm"]);
        }
      }
    });
    this.unSubscirePeriod = this.store.pipe(select("period")).subscribe((period: Period) => {
      if(period){
        const { fromDate, toDate } = period;
        this.getOverViewCounter(
          period.period.package_code,
          fromDate ? moment(`${fromDate.year}-${fromDate.month}-${fromDate.day}`, "YYYY-MM-DD").format("YYYY-MM-DD") : "", 
          toDate ? moment(`${toDate.year}-${toDate.month}-${toDate.day}`, "YYYY-MM-DD").format("YYYY-MM-DD") : "" 
        );
      }
    });
  }

  getOverViewCounter(period: string, fromDate: string, toDate: string) {
    const args = {
      period,
      fromDate,
      toDate,
      key: environment.key
    };
    this.isLoading = true;
    this.companyService.getOverviewCounter(args).subscribe((response: any) => {
      if(response){
        this.overviews = response;
      }
      this.isLoading = false;
    });
  }

}
