import { Component, OnInit, Input, Inject } from '@angular/core';
import { CompanyService } from 'src/app/services/aih/company-api.service';
import { PackageModel } from 'src/app/models/aih/Package.model';
import { NgForm } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Reducers } from 'src/app/reducers';
import { addPeriod, Period } from 'src/app/reducers/Period';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import Auth from 'src/app/models/Authenticate';
import * as moment from "moment";
import { addPackages } from 'src/app/reducers/Packages';
import { toDateFormat, getNgDate } from 'src/app/utilities';

@Component({
  selector: 'app-form-period',
  templateUrl: './form-period.component.html',
  styleUrls: ['./form-period.component.scss']
})
export class FormPeriodComponent implements OnInit {
  public isLoading = false;
  public packages: PackageModel[] = [];
  date = new Date();
  // public fromDate: NgbDate = new NgbDate(this.date.getFullYear(), this.date.getMonth() + 1, this.date.getDate());
  // public toDate: NgbDate = this.fromDate;
  public fromDate: NgbDate | null = null;
  public toDate: NgbDate | null = null;
  public period: string = "";
  public auth: Auth;
  @Input() submitLabel: string = "OK";
  @Input() size: "sm" | "lg" | "" = "sm";
  position = window.innerWidth <= 768 ? "left" : "right";

  public min: NgbDate | null = null;
  public max: NgbDate | null = null;
  
  constructor(
    protected companyService: CompanyService,
    @Inject(Store) protected store: Store<Reducers>
  ) { }

  ngOnInit() {
    this.store.pipe(select("period")).subscribe((response: Period) => {
      if (response) {
        const { period } = response;
        if(period){
          const {package_code, start_date, end_date} = period;
          this.period = package_code;
          this.min = getNgDate(start_date);
          this.max = getNgDate(end_date)
        }
      }
    });
    this.store.pipe(select("auth")).subscribe((auth: Auth) => {
      if (auth) {
        this.getPackgeCode(auth.user_username);
        this.auth = auth;
      }
    });
  }

  getPeriodDate(time: string) {
    return moment(time).format("YY-MM-DD");
  }

  onSubmit(e: NgForm) {
    const { period } = e.value;
    const value = this.packages.find(node => node.package_code === period);
    if(value){
      this.store.dispatch(addPeriod(value, this.fromDate, this.toDate));
    }
  }

  getPackgeCode(username: string) {
    if(this.isLoading){
      return;
    }
    this.isLoading = true;
    this.companyService
      .getListPackageByUser(username, environment.key)
      .subscribe((response: PackageModel[]) => {
        this.packages = response;
        // console.log(response);
        this.isLoading = false;
        if (this.packages.length) {
          this.store.dispatch(addPackages(response));
          if (!this.period) {
            const value = this.packages[0];
            this.store.dispatch(
              addPeriod( value )
            );
          }
        }
      });
  }

  dateToString(value: null | NgbDate, placeholder = "") {
    return value ? toDateFormat(value) : placeholder;
  }

  handleChangeDate(type: string, e: any) {
    console.log(e);
    this[type] = e;
  }

  onChangeDate({ fromDate, toDate }: any) {
    this.fromDate = fromDate;
    this.toDate = toDate;
  }

}
