import { Component, OnInit } from '@angular/core';
import { Response } from 'src/app/models/Response';
import { setCookie } from 'src/app/helpers/cookie';
import { COOKIE_AUTH } from 'src/app/Key';
import { logout } from 'src/app/reducers/Auth';
import Auth from 'src/app/models/Authenticate';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Reducers } from 'src/app/reducers';
import Swal from 'sweetalert2';
import { forkJoin } from 'rxjs';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'app-logout-btn',
  templateUrl: './logout-btn.component.html',
  styleUrls: ['./logout-btn.component.scss']
})
export class LogoutBtnComponent implements OnInit {
  isLogged = false;
  auth: Auth;
  isOpen = false;

  constructor(
    protected store: Store<Reducers>,
    protected authService: AuthService,
    protected translate: TranslateService
  ) { }

  ngOnInit() {
    this.store.pipe(select("auth")).pipe().subscribe(values => {
      if (values) {
        this.auth = values;
        this.isLogged = true;
      }
    });
  }

  logout() {
    const { user_username, token } = this.auth;
    this.authService.logout(user_username, token).subscribe(({ ResponseMessage, StatusCode }: Response) => {
      if (!StatusCode) {
        return this.logoutErrors(ResponseMessage);
      }
      setCookie(COOKIE_AUTH);
      this.store.dispatch(logout());
      window.location.href = "/";
    });
    return false;
  }

  logoutErrors(msg: string) {
    forkJoin(
      [
        this.translate.get("text_logout_fail"),
        this.translate.get('text_close')
      ]
    ).subscribe(([title, cancelButtonText]) => {
      Swal.fire({
        title,
        cancelButtonText
      });
    })
  }

}
