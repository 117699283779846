// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


// Development
export const environment = {
  production: true,
  main: "https://www.aih.com.vn/",
  backend: "https://backend.aih.com.vn",
  api: "https://venus.aih.com.vn/", // "https://api.aih.tqdesign.vn/", //
  aih_api: "https://aqua.aih.com.vn:8181",
  mapApiKey: "AIzaSyD77nJoVaeLUFJAGd_wp0qmTiNUJemumnE",
  secretKey: "GwBqWPA4ezINGtLPzwNilw==",
  host: 'https://aih.com.vn',
  recaptchaSiteKey: "6LeHVrIUAAAAAOwn3IAMUMA1TFetq2Fuw8yB5WjK",
  pid: "6FAC960C-6F0D-4863-B8E2-D8C08E454BA7",
  key: "btIhIdEW87fz5pE/EfdnLQ==",
  loginViUrl: 'http://portal.aih.com.vn?lang=vi',
  loginEnUrl: 'http://portal.aih.com.vn?lang=en'
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
