import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";

@Component({
  selector: 'app-patient-infomation',
  templateUrl: './patient-infomation.component.html',
  styleUrls: ['./patient-infomation.component.scss']
})
export class PatientInfomationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  handleScroll(){
    const form = $("#form-confim-appointment");
    const width = $("body").width();
    $("body, html").animate({
      scrollTop: width >= 1200 ? 0 : form.offset().top
    }, 2000, function(){
      form.addClass("animated flash");
    });
  }

}
