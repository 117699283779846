import { Component, OnInit, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Reducers } from 'src/app/reducers';
import Auth from 'src/app/models/Authenticate';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PersonalService } from 'src/app/services/aih/personal-api.service';
import { logout, login } from 'src/app/reducers/Auth';
import { COOKIE_AUTH } from 'src/app/Key';
import { setCookie } from 'src/app/helpers/cookie';
import { updatePatient } from 'src/app/reducers/Patient';
import { Patient } from 'src/app/models/aih/Patient.model';
import { TranslateService } from '@ngx-translate/core';
import * as _ from "lodash";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-logged-page',
  templateUrl: './logged-page.component.html',
  styleUrls: ['./logged-page.component.scss']
})
export class LoggedPageComponent implements OnInit {
  auth: Auth;
  isLoading = true;

  constructor(
    @Inject(Router) protected router: Router,
    public authService: AuthService,
    public personalService: PersonalService,
    private translate: TranslateService,
    protected store: Store<Reducers>
  ) { }

  ngOnInit() {
    this.store.pipe(select("auth")).subscribe(values => {
      if (values) {
        this.auth = values;
        this.checkPatient();
        if (this.router.url === "/") {
          this.router.navigate(["dashboard"]);
        }
      } else {
        this.router.navigate(["/"]);
      }
    });
  }

  checkPatient() {
    this.store.pipe(select("patient")).subscribe(values => {
      if (!values) {
        this.authSuccess(this.auth);
      }
    });
  }

  authSuccess(data: Auth, remember: boolean = false) {
    this.personalService.getPatients(data.user_username).subscribe(response => {
      if (response && _.isArray(response)) {
        if (response.length >= 2) {
          return this.showSelectPatients(data, response, remember);
        } else if (response.length === 1) {
          return this.redirectToLoggedPage(data, response[0], remember);
        } else {
          return this.redirectToLoggedPage(data, null, remember);
        }
      }
    });
  }

  showSelectPatients(data: Auth, response: Patient[], remember = false) {
    this.translate.get([
      "please select patient",
      "please select a patient"
    ]).subscribe((messages) => {
      const patients = {};
      response.map((node, index) => {
        patients[index] = `(${node.visible_patient_id}) - ${this.translate.currentLang === "vi" ? node.patient_name : node.patient_name_e}`;
      });
      return Swal.fire({
        title: messages["please select patient"],
        input: "radio",
        customClass: {
          container: "select-patient"
        },
        inputOptions: patients,
        icon: "question",
        inputValidator: (value) => {
          if (!value) {
            return messages["please select a patient"]
          }
        }
      }).then(result => {
        if (result.value) {
          const patient = response[Number(result.value)];
          return this.redirectToLoggedPage(data, patient, remember);
        } else {
          // Đăng xuất nếu không chọn bệnh nhân
          setCookie(COOKIE_AUTH);
          this.store.dispatch(logout());
          window.location.href = "/";
          return this.authService.logout(data.user_username, data.token)
          // return this.showSelectPatients(data, response, remember);
        }
      });
    });
  }
  /**
   * Chuyển hướng sang trang đã đăng nhập
   * @param data 
   * @param patient 
   * @param remember 
   */
  redirectToLoggedPage(data: Auth, patient?: Patient, remember?) {
    // Lưu tài khoản khi tắt trình duyệt
    if (remember) {
      setCookie(COOKIE_AUTH, JSON.stringify(data));
    }

    if (patient) {
      // thông tin bệnh nhân
      this.store.dispatch(updatePatient(patient));
    }

    // thông tin tài khoản
    this.store.dispatch(login(data));
  }
}
