import { Injectable } from "@angular/core";
import { RestApiService } from './rest-api.service';

@Injectable()
export class GuidService {
    constructor(private http: RestApiService) { }

    getCategries(lang: string = "vi-VN") {
        const options = {
            "search": "",
            "publish": 1,
            "rowperpage": 999,
            "pageselected": 1,
            "cate_type": "app_guidscate",
            "lang": lang,
            "sort": []
        };
        return this.http.post("category/list", options);
    }

    getBanner(lang: string = "vi-VN") {
        const defaultArgs = {
            "search": "",
            "publish": 1,
            "rowperpage": 999,
            "pageselected": 1,
            "media_type": ["app_bannerlogin"],
            "cate_id": [],
            "lang": lang,
            "sort": []
        };
        return this.http.post("media/list", defaultArgs);
    }

    getPost(cat: number[], lang: string = "vi-VN") {
        const defaultArgs = {
            "search": "",
            "publish": 1,
            "rowperpage": 999,
            "pageselected": 1,
            "media_type": ["app_guids"],
            "cate_id": cat,
            "lang": lang,
            "sort": []
        };
        return this.http.post("media/list", defaultArgs);
    }
}
