import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-guild-detail',
  templateUrl: './guild-detail.component.html',
  styleUrls: ['./guild-detail.component.scss']
})
export class GuildDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
