import { HistoryExamed } from '../models/HistoryExamed';

export const SELECT_HISTORY = "SELECT_HISTORY";
export const DELECT_HISTORY = "DELECT_HISTORY";

export const selectHistory = (history: HistoryExamed) => ({type: SELECT_HISTORY, history});
export const removeHistory = () => ({type: DELECT_HISTORY})
export function historyReducer(state: null | string = null, action: any){
    switch(action.type){
        case SELECT_HISTORY:
            return action.history;
        case DELECT_HISTORY:
            return null;
        default:
            return state;
    }
}