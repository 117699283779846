import {Injectable} from '@angular/core';
import {RestApiService} from './rest-api.service';
import {BaseService} from './base.service';
import {TranslateService} from '@ngx-translate/core';

export interface OtpResponse {
  ResponseMessage: string
  StatusCode: boolean
  expiry_date: string
  is_sent: boolean
  security_code: string
  validation_id: string
}

export interface OtpVerify {
  mobile: string
  validation_id: string
  token: string
}

@Injectable()
export class OtpService extends BaseService {
  constructor(private http: RestApiService, translate: TranslateService) {
    super(translate);
  }

  public static isExpires(expiryDate: string) {
    const last = new Date(expiryDate).getTime();
    const now = new Date().getTime();
    return last - now;
  }


  public sendOTPToPhone(phone: string | number) {
    return this.http.post(`/validation/request`, {
      mobile: phone,
      type: 1,
      lang: this.getCurrentLocal()
    });
  }

  public verfiryOTP({mobile, token, validation_id}: OtpVerify) {
    return this.http.post(`/validation/verify`, {
      mobile,
      validation_id,
      token,
      type: 1,
      auto_login: 1
    });
  }
}
