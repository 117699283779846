
import {
  NgbCalendar,
  NgbDate,
} from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { momentToNgbDate, stringPadStart, maxMomentToNgbDate } from 'src/app/utilities';

@Component({
  selector: 'app-from-date-to-date',
  templateUrl: './from-date-to-date.component.html',
  styleUrls: ['./from-date-to-date.component.scss'],
})
export class FromDateToDateComponent {
  hoveredDate: NgbDate;

  expanded = false;
  @Input() doctorSchedule: any;
  @Input() animateAfter: boolean;
  @Input() date: Date = new Date();
  @Output() onChange = new EventEmitter<any>();
  fromDate: null | NgbDate = new NgbDate(this.date.getFullYear(), this.date.getMonth() + 1, this.date.getDate());
  toDate: null | NgbDate = new NgbDate(this.date.getFullYear(), this.date.getMonth() + 1, this.date.getDate());

  public isDisabled: any;

  public disableDays = [];

  constructor(private calendar: NgbCalendar, ) {
  }

  getDateString(){
    if(this.fromDate && this.toDate){
      return `${this.fromDate.day}/${this.fromDate.month}/${this.fromDate.year} - ${this.toDate.day}/${this.toDate.month}/${this.toDate.year}`;
    }else if(this.fromDate){
      return `${this.fromDate.day}/${this.fromDate.month}/${this.fromDate.year}`;
    }
    return "";
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.expanded = false;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.onChange.emit({ fromDate: this.fromDate, toDate: this.toDate });
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  ngOnInit(): void {
    this.fromDate = new NgbDate(2019, 11, 1);
    this.toDate = new NgbDate(2019, 11, 31);
    this.onChange.emit({ fromDate: this.fromDate, toDate: this.toDate });
  }

  toggleExpandDate() {
    this.expanded = !this.expanded;
  }

  expandDate(expand: boolean) {
    this.expanded = expand;
  }

  onClickOutSide(e) {
    if (this.expanded) {
      this.expanded = false;
    }
  }

  getMarkDisableDate() {
    return (date: NgbDate) => {
      const rs = this.disableDays.find(item => {
        return item.year === date.year && item.month === date.month && item.day === date.day;
      });
      return this.calendar.getWeekday(date) > 6 || rs;
    };
  }

  handleDisableDays(offs) {
    this.disableDays = offs.map(item => {
      return momentToNgbDate(item);
    });
  }
}
