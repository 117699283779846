import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Reducers } from 'src/app/reducers';
import Auth from 'src/app/models/Authenticate';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { GuidService } from 'src/app/services/guid.service';
import * as _ from "lodash";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  width = window.innerWidth;

  height = this.width >= 1200 ? 'calc(100vh - 40px)' : `${this.width / 1.5}px`;

  subscired: Subscription;

  auth: Auth | null;

  slides = [
    "/assets/images/login-slider-1.png",
    "/assets/images/login-slider-2.png",
    "/assets/images/login-slider-3.png",
  ];

  constructor(
    @Inject(Store) protected store: Store<Reducers>,
    @Inject(Router) protected router: Router,
    protected guidService: GuidService
  ) { }

  /**
   * Check is authenticated
   * If authenticated, redirect to logged page
   */
  ngOnInit() {
    this.subscired = this.store.pipe(select("auth")).subscribe(auth => {
      if(auth){
        this.router.navigate(["/dashboard"]);
      }
    });
    this.guidService.getBanner().subscribe((response: any) => {
      if(response && response.Media){
        const { Media } = response;
        if(_.isArray(Media)){
          const slides = [];
          Media.map(({media_thumb}) => {
            slides.push(`${environment.backend}${media_thumb}`);
          });
          this.slides = slides;
        }
      }
    })
  }
  
  ngOnDestroy(){
    this.subscired.unsubscribe();
  }

}
