import { Action } from '@ngrx/store';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { PackageModel } from '../models/aih/Package.model';

export const ADD_PERIOD = "ADD_PERIOD";
export const DEL_PERIOD = "DEL_PERIOD";

export const addPeriod = (period: PackageModel, fromDate?: NgbDate, toDate?: NgbDate) => ({ type: ADD_PERIOD, period, fromDate, toDate });
export const removePeriod = () => ({ type: DEL_PERIOD });

export interface Period {
    period: PackageModel;
    fromDate: NgbDate;
    toDate: NgbDate;
}

export interface PeriodAction extends Action, Period {}

export function periodReducer(state: null | Period = null, action: PeriodAction) {
    switch (action.type) {
        case ADD_PERIOD:
            const { period, fromDate, toDate } = action;
            return { period, fromDate, toDate };
        case DEL_PERIOD:
            return null;
        default:
            return state;
    }
}
