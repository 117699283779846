import { Injectable } from "@angular/core";
import { RestApiService } from '../rest-api.service';
import {environment} from '../../../environments/environment';

@Injectable()
export class AihApiService extends RestApiService {
    public apiURL = environment.aih_api;

    public changePassword(user_username: string, user_password_new: string, pin_code: string){
        return this.post("/api/hccout/changePasswordHCC", {user_username, user_password_new, pin_code, key: environment.key});
    }
    public resetPassword(user_username: string, pin_code: string){
        return this.post("/api/hccout/resetPasswordHCC", {user_username, pin_code, key: environment.key});
    }
    public getPids(username: string){
      return this.post("/api/hccout/listpids", {username, key: environment.key});
    }
}
