import {Component, OnInit, Output, EventEmitter, Inject} from '@angular/core';
import {NgForm} from '@angular/forms';
import {forkJoin} from 'rxjs';
import {AihApiService} from 'src/app/services/aih/rest-api.service';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  isValid = true;

  @Output() onClickRememberMe = new EventEmitter();

  constructor(
    protected aihService: AihApiService,
    protected authService: AuthService,
    @Inject(Router) protected router: Router,
    protected translate: TranslateService
  ) {
  }

  ngOnInit() {
  }

  resetPassword($form: NgForm) {
    this.isValid = $form.valid;
    forkJoin([
      this.translate.get('Success'),
      this.translate.get('Your password has been sent by SMS'),
      this.translate.get('Error occurred'),
      this.translate.get('Your phone number or date of birth is incorrect'),
    ]).subscribe(([success, successTxt, error, msg]) => {
      if (!$form.valid) {
        return Swal.fire(error, '', 'warning');
      }
      const {value: {account, date_of_birth}} = $form;
      this.aihService.getPids(account).subscribe((patients: any) => {
        const findPatient = patients && patients.find(node => moment(node.date_of_birth).format('DD/MM/YYYY') === moment(date_of_birth).format('DD/MM/YYYY'));
        if (patients.length && !findPatient) {
          return Swal.fire(msg, '', 'warning');
        } else {
          this.authService.reset(account).subscribe(({StatusCode}: any) => {
            if (StatusCode === 1) {
              this.router.navigate(['/']);
              return Swal.fire(success, successTxt, 'success');
            } else {
              return Swal.fire(error, '', 'warning');
            }
          });
        }
      });
    });
  }

  onSubmit($form: NgForm) {
    this.isValid = $form.valid;
    forkJoin([
      this.translate.get('Success'),
      this.translate.get('Your password has been sent by SMS'),
      this.translate.get('Error occurred'),
      this.translate.get('Your phone number or PIN Code is incorrect'),
    ]).subscribe(([success, successTxt, error, msg]) => {
      if (!$form.valid) {
        return Swal.fire(error, '', 'warning');
      }
      const {value: {account, pin_code}} = $form;
      this.aihService.resetPassword(account, pin_code).subscribe(response => {
        if (response === 'OK') {
          this.router.navigate(['/']);
          return Swal.fire(success, successTxt, 'success');
        } else {
          return Swal.fire(error, msg, 'error');
        }
      });
    });
  }

}
