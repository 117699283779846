import { Injectable } from "@angular/core";
import { AihApiService } from './rest-api.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class PersonalService extends AihApiService {
    /**
     * Lấy thông tin cá nhân
     */
    getUserProfile(pid: string, key: string, username: string) {
        return this.post("/api/hccout/getpatienthccbypvid", { pid, key, username });
    }

    /**
     * Lấy danh sách bệnh nhân của tài khoản
     */
    getPatients(username: string) {
        return this.post("/api/hccout/listpids", { key: environment.key, username });
    }

    /**
     * Lấy lịch sử khám bệnh
     * @param username: tên tài khoản
     */
    getListHistoryExamed(username: string, pid?: string) {
        return this.post("/api/hccout/getlisthistoryexamed", { key: environment.key, username, pid });
    }

    getAppointMent(pid: string, key: string) {
        return this.post("/api/hccout/getappointment", { key, pid });
    }

    /**
     * Cập nhật tiền sử bệnh án
     * @param data 
     */
    setMedicalHistory(data: any) {
        return this.post("/api/hccout/addPastMedicHistory", data);
    }

    /**
     * Lấy tiền sử bệnh án
     * @param pid 
     * @param key 
     */
    getMedicalHistory(pid: string, key: string) {
        return this.post("/api/hccout/getpastmedichistory", { pid, key });
    }

    getPeriodIndvidual(pid: string, key: string) {
        return this.post("/api/hccout/getperiodindvidual", { pid, key });
    }

    cancelAppointment(pid: string, key: string) {
        return this.post("/api/hccout/cancelappointment", { patient_visit_id: pid, key });
    }

    createUser(phone: string, key: string) {
        return this.post("/api/hccout/regPhoneHCC", { phone, key });
    }

    getCollection(pid: string, key: string) {
        return this.post("/api/hccout/getcollectionindividual", { pid, key });
    }

    getListFileResult(pid: string, key: string) {
        return this.post("/api/hccout/getlistfileresult", { pid, key });
    }
}