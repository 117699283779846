import { Component, OnInit, OnDestroy } from '@angular/core';
import { GuidService } from 'src/app/services/guid.service';
import { TranslateService } from '@ngx-translate/core';
import { isObject } from 'lodash';
import { environment } from 'src/environments/environment';

interface Guid {
  name: string;
  content?: string;
  file?: string;
  id: number;
}

@Component({
  selector: 'app-user-guids',
  templateUrl: './user-guids.component.html',
  styleUrls: ['./user-guids.component.scss']
})
export class UserGuidsComponent implements OnInit {


  categories = [{ name: "All", id: "" }];

  activeCat: number | null = null;

  defaults: null[] = [null, null, null, null, null, null, null, null, null, null];

  // list of guids
  guids: null[] | Guid[] = this.defaults;

  // view single guid content
  guid: null | Guid = null;

  lang: string;

  constructor(
    protected guidService: GuidService,
    protected translate: TranslateService
  ) { }

  ngOnInit() {
    this.getCats();
  }

  getCats() {
    this.translate.onLangChange.subscribe(({ lang }) => this.onChangeLang(lang))
    this.onChangeLang(this.translate.currentLang);
  }

  onChangeLang(lang: string) {
    this.lang = lang === "vi" ? "vi-VN" : "en-US";
    this.getCategories();
  }

  loading = false;
  getCategories() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.guidService.getCategries(this.lang).subscribe((response: any) => {
      this.loading = false;
      if (response && isObject(response)) {
        const { Categories }: any = response;
        this.translate.get("All").subscribe(all => {
          const category = [{ name: all, id: null }];
          Categories.map(({ cate_name, cate_id }) => {
            category.push({
              name: cate_name,
              id: cate_id
            });
          });
          this.categories = category;
          this.setActiveCat(null);
        });
      }
    });
  }

  setActiveCat(id: number | null) {
    this.activeCat = id;
    this.resetSingleGuid();
    this.guids = this.defaults;
    this.getGuids();
  }

  // get fuids
  async getGuids() {
    const results = await this.fetchGuids(this.activeCat);
    this.guids = results;
  }

  // fetch http to get guids
  fetchGuids(cate_id: number | null): Promise<null[] | Guid[]> {
    return new Promise((resolve) => {
      const cats = [];
      if (cate_id) {
        cats.push(cate_id);
      }
      this.guidService.getPost(cats, this.lang).subscribe(response => {
        if (response && isObject(response)) {
          const { Media }: any = response;
          if (Media) {
            return resolve(Media.map(({ media_title, media_id, media_longdesc, media_file }) => {
              return {
                name: media_title,
                id: media_id,
                content: media_longdesc,
                file: environment.backend + "/" + media_file
              }
            }));
          }
        }
        return resolve([]);
      })
    });
  }

  resetSingleGuid() {
    this.guid = null;
  }

  showDetail(item: Guid) {
    item.content = item.content;
    this.guid = item;
  }

}
