import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoggedPageComponent } from './components/logged-page/logged-page.component';
import { OverviewsComponent } from './components/overviews/overviews.component';
import { UserGuidsComponent } from './components/user-guids/user-guids.component';
import { AppoinmentComponent } from './components/appoinment/appoinment.component';
import { PatientInfomationComponent } from './components/patient-infomation/patient-infomation.component';
import { ResultMecialComponent } from './components/result-mecial/result-mecial.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { RegisterComponent } from './components/register/register.component';
import { HCComponent } from './print/hc/hc.component';

const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
    children: [
      { path: "", component: LoginFormComponent },
      { path: "forgot-password", component: ForgotPasswordComponent },
      { path: "change-password", component: ChangePasswordComponent },
      { path: "sign-up", component: RegisterComponent },
    ]
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent
  },
  {
    path: "dashboard",
    component: LoggedPageComponent,
    children: [
      { path: "", component: OverviewsComponent },
      { path: "guids", component: UserGuidsComponent },
      { path: "appoinment", component: AppoinmentComponent },
      { path: "confirm", component: PatientInfomationComponent },
      { path: "result", component: ResultMecialComponent },
      { path: "hc", component: HCComponent },
    ]
  },

];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: false,
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
