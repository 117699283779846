import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import Swal from "sweetalert2";
import { PersonalService } from 'src/app/services/aih/personal-api.service';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-btn-cancel-appointment',
  templateUrl: './btn-cancel-appointment.component.html',
  styleUrls: ['./btn-cancel-appointment.component.scss']
})
export class BtnCancelAppointmentComponent implements OnInit {

  @Input() pid: string;
  @Output() onCancel = new EventEmitter();
  constructor(
    protected personalService: PersonalService,
    protected translate: TranslateService
  ) { }

  ngOnInit() {
  }

  handleCancelAppointment() {
    forkJoin([
      this.translate.get("Cancel Appoitment"),
      this.translate.get("text_register_fail"),
      this.translate.get("cancel"),
      this.translate.get("Ok"),
    ])
      .subscribe(([title, fail, cancel, ok]) => {
        Swal.fire({
          title: title,
          showCancelButton: true,
          cancelButtonText: cancel,
          confirmButtonText: ok,
          customClass: {
            confirmButton: "text-white bg-red"
          },
          showConfirmButton: true
        }).then(response => {
          if (response.value && this.pid) {
            this.personalService.cancelAppointment(this.pid, environment.key).subscribe(response => {
              if (response === "OK") {
                this.onCancel.emit(true);
              } else {
                Swal.fire(fail, "", "error");
                this.onCancel.emit(false);
              }
            });
          }
        });
      });

  }

}
