import Auth from '../models/Authenticate';
import { Action } from '@ngrx/store';
import { getCookie, setCookie } from '../helpers/cookie';
import { COOKIE_AUTH, COOKIE_PATIENT } from '../Key';

export const ADD_AUTH = "ADD_AUTH";
export const DEL_AUTH = "DEL_AUTH";

export const login = (auth: Auth) => ({ type: ADD_AUTH, auth });
export const logout = () => ({ type: DEL_AUTH });

interface AuthAction extends Action {
    readonly auth?: Auth;
}

let initState: Auth | null = null;

// get auth by cookie [used remember me in login page]
let rawProfile = sessionStorage.getItem(COOKIE_AUTH);
if(!rawProfile){
    rawProfile = getCookie(COOKIE_AUTH);
}
if(rawProfile){
    try{
        initState = JSON.parse(rawProfile);
    }catch(e){ 
        /** IGRONE */
        console.error(e);
    }
}

export function authReducer(state: null | Auth = initState, action: AuthAction){
    switch (action.type) {
        case ADD_AUTH:
            const { auth } = action;
            sessionStorage.setItem(COOKIE_AUTH, JSON.stringify(auth));
            return auth;
        case DEL_AUTH:
            sessionStorage.removeItem(COOKIE_PATIENT);
            setCookie(COOKIE_PATIENT, "");
            sessionStorage.removeItem(COOKIE_AUTH);
            setCookie(COOKIE_AUTH, "");
            return null;
        default:
            return state;
    }
};
