import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PersonalService } from 'src/app/services/aih/personal-api.service';
import { Store, select } from '@ngrx/store';
import { Reducers } from 'src/app/reducers';
import { Patient } from 'src/app/models/aih/Patient.model';
import { environment } from 'src/environments/environment';
import * as _ from "lodash";
import { HistoryExamed } from 'src/app/models/HistoryExamed';
import { addPatient } from 'src/app/reducers/Patient';
import * as moment from "moment";

@Component({
  selector: 'app-patient-result',
  templateUrl: './patient-result.component.html',
  styleUrls: ['./patient-result.component.scss']
})
export class PatientResultComponent implements OnInit {

  isLoading = false;

  profile: null | Patient = null;
  // {
  // patient_id: "",
  // visible_patient_id: "",
  // patient_visit_id: "",
  // patient_name: "",
  // patient_name_e: "",
  // date_of_birth: "",
  // gender: "",
  // phone_number: "",
  // email: "",
  // address: "",
  // company: "",
  // date_of_exam: "",
  // contact_person: "",
  // };

  history: HistoryExamed;

  constructor(
    @Inject(TranslateService) protected translate: TranslateService,
    @Inject(PersonalService) protected personalService: PersonalService,
    protected store: Store<Reducers>
  ) { }

  ngOnInit() {
    this.store.pipe(select(state => ({
      auth: state.auth,
      patient: state.patient,
      history: state.history
    }))).subscribe(({ auth, patient, history }) => {
      this.history = history;
      if (patient) {
        this.profile = patient;
      } else if (auth && history) {
        return this.getProfile(auth.user_username);
      }
    });
  }

  getDate(date: string) {
    return moment(date).format("DD-MM-YYYY");
  }

  getProfile(username: string) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    const data = {
      "pid": this.history.patient_visit_id,
      "username": username,
      "key": environment.key
    };
    this.personalService.getUserProfile(data.pid, data.key, data.username).subscribe((profile: Patient) => {
      if (profile && _.isObject(profile)) {
        this.store.dispatch(addPatient(profile));
      }
      this.isLoading = false;
    });
  }

}
