import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import Swal from "sweetalert2";
import { PersonalService } from "src/app/services/aih/personal-api.service";
import * as _ from "lodash";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { Store, select } from "@ngrx/store";
import { Reducers } from "src/app/reducers";
import { Subscription, forkJoin } from "rxjs";
import { HistoryExamed } from "src/app/models/HistoryExamed";
import Auth from "src/app/models/Authenticate";
import { Period } from "src/app/reducers/Period";
import { updatePatient } from "src/app/reducers/Patient";
import { Patient } from "src/app/models/aih/Patient.model";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
import * as moment from "moment";

@Component({
  selector: "app-medical-history",
  templateUrl: "./medical-history.component.html",
  styleUrls: ["./medical-history.component.scss"],
  exportAs: "var",
})
export class MedicalHistoryComponent implements OnInit, OnDestroy {
  placeholder = window.innerWidth <= 575 ? "Ngày tiêm gần nhất" : "";

  tien_su_y_khoa: any = {
    noi_khoa: new Set(),
    noi_khoa_khac: "",
    ngoai_khoa: true,
    phu_khoa: {
      pk1: "",
      pk2: "",
      pk3: "",
      pk4: "",
      pk5: "",
      pk6: "",
      pk7: "",
    },
    phu_khoa_cau_hoi: { tinh_trang: false, phuong_phap: "" },
    benh_ly_gia_dinh: {
      bo: { benh_ly: "", nguyen_nhan_da_mat: "" },
      me: { benh_ly: "", nguyen_nhan_da_mat: "" },
      anh_chi_em: { benh_ly: "", nguyen_nhan_da_mat: "" },
      con: { benh_ly: "", nguyen_nhan_da_mat: "" },
      ho_ngoai: { benh_ly: "", nguyen_nhan_da_mat: "" },
      ho_noi: { benh_ly: "", nguyen_nhan_da_mat: "" },
    },
    thoi_quen_sinh_hoat: {
      hut_thuoc: {
        name: "Hút thuốc",
        tinh_trang: false,
        ngay: "",
        tuan: "",
        thang: "",
      },
      uong_bia: {
        name: "Uống bia",
        tinh_trang: false,
        ngay: "",
        tuan: "",
        thang: "",
      },
      uong_ruu: {
        name: "Uống rượu",
        tinh_trang: false,
        ngay: "",
        tuan: "",
        thang: "",
      },
      uong_ruu_manh: {
        name: "Uống rượu mạnh",
        tinh_trang: false,
        ngay: "",
        tuan: "",
        thang: "",
      },
      caphe: {
        name: "Cà phê",
        tinh_trang: false,
        ngay: "",
        tuan: "",
        thang: "",
      },
      tra: { name: "Trà", tinh_trang: false, ngay: "", tuan: "", thang: "" },
      tap_the_duc: {
        name: "Tập thể dục",
        tinh_trang: false,
        ngay: "",
        tuan: "",
        thang: "",
        loai_hinh: "",
      },
    },
    thuoc_dang_dung: [
      { ten_thuoc: "", lieu_luong: "", da_dung_bao_lau: "" },
      { ten_thuoc: "", lieu_luong: "", da_dung_bao_lau: "" },
      { ten_thuoc: "", lieu_luong: "", da_dung_bao_lau: "" },
    ],
    ho_so_tiem_chung: {
      bcg: { tinh_trang: "unknown", ngay_tiem_gan_nhat: "" },
      diphtheria: { tinh_trang: "unknown", ngay_tiem_gan_nhat: "" },
      tetanus: { tinh_trang: "unknown", ngay_tiem_gan_nhat: "" },
      polio: { tinh_trang: "unknown", ngay_tiem_gan_nhat: "" },
      hepatitisa: { tinh_trang: "unknown", ngay_tiem_gan_nhat: "" },
      hepatitisb: { tinh_trang: "unknown", ngay_tiem_gan_nhat: "" },
      typhoid_fever: { tinh_trang: "unknown", ngay_tiem_gan_nhat: "" },
      rabies: { tinh_trang: "unknown", ngay_tiem_gan_nhat: "" },
    },
    ho_so_tiem_chung_khac: "",
    thong_tin_benh_nhan: {
      cso: false,
      date_of_exam: null,
    },
    flag_blocked: false,
    di_ung_thuoc: { ten_thuoc: "", tinh_trang: "" },
  };

  frontInternalMedicine = [
    [
      { name: "Tiểu đường", checked: true },
      { name: "Cao huyết áp", checked: true },
      { name: "Cholesterol cao", checked: true },
      { name: "Bệnh lý tuyến giáp", checked: false },
      { name: "Bệnh bạch cầu", checked: false },
    ],
    [
      { name: "Vảy nến", checked: false },
      { name: "Đau ngực", checked: false },
      { name: "Bệnh tim", checked: false },
      { name: "Thuyên tắc phổi", checked: false },
      { name: "Suyễn", checked: false },
    ],
    [
      { name: "Tràn khí màng phổi", checked: false },
      { name: "Đột quỵ", checked: false },
      { name: "Cườm", checked: false },
      { name: "Bệnh thận", checked: false },
      { name: "Sỏi thận", checked: false },
    ],
    [
      { name: "Bệnh Crohn", checked: false },
      { name: "Thiếu máu", checked: false },
      { name: "Viêm gan (Loại …..)", checked: false },
      { name: "Sốt thấp khớp", checked: false },
      { name: "Viêm phổi", checked: false },
    ],
    [
      { name: "Bướu giáp", checked: false },
      { name: "Âm thổi tim", checked: false },
      { name: "Động kinh", checked: false },
      { name: "Viêm đại tràng", checked: false },
      { name: "Lao", checked: false },
    ],
    [
      { name: "Ung thư", checked: false },
      { name: "Đau hoặc loét dạ dày", checked: false },
    ],
  ];

  vaccins = [
    { code: "bcg", name: "Bcg" },
    { code: "diphtheria", name: "Diphtheria" },
    { code: "tetanus", name: "Tetanus" },
    { code: "polio", name: "Polio" },
    { code: "hepatitisa", name: "Hepatitis A" },
    { code: "hepatitisb", name: "Hepatitis B" },
    { code: "typhoid_fever", name: "Typhoid Fever" },
    { code: "rabies", name: "Rabies" },
  ];

  gynecological = [
    {
      slug: "pk1",
      name: "Bạn đã mang thai bao nhiêu lần ?",
      status: false,
      value: "",
    },
    {
      slug: "pk2",
      name: "Bạn đã bỏ thai hoặc sẩy thai bao nhiêu lần ?",
      status: false,
      value: "",
    },
    { slug: "pk3", name: "Bạn có bao nhiêu con ?", status: false, value: "" },
    {
      slug: "pk4",
      name: "Bạn đang sử dụng phương pháp ngừa thai nào ?",
      status: false,
      value: "",
    },
    {
      slug: "pk5",
      name: "Bạn đã từng có bệnh lý phụ khoa không ?",
      status: false,
      value: "",
    },
    {
      slug: "pk6",
      name: "Ngày đầu tiên của kỳ kinh gần nhất",
      status: false,
      value: "",
    },
    {
      slug: "pk7",
      name: "Ngày xét nghiệm tầm soát ung thư cổ tử cung gần nhất:",
      status: false,
      value: "",
    },
  ];

  livingHabits = [
    { slug: "hut_thuoc", name: "Hút thuốc", status: false, mime: "điếu" },
    { slug: "uong_bia", name: "Bia", status: false, mime: "chai" },
    { slug: "uong_ruu", name: "Rượu", status: false, mime: "ly" },
    { slug: "uong_ruu_manh", name: "Rượu mạnh", status: false, mime: "oz" },
    { slug: "caphe", name: "Cà phê", status: false, mime: "tách" },
    { slug: "tra", name: "Trà", status: false, mime: "tách" },
    {
      slug: "tap_the_duc",
      name: "Tập thể dục",
      status: false,
      mime: "lần",
      day: "lần",
      month: "lần",
      other: " ",
    },
  ];

  isLoading = false;

  isSubmiting = false;

  constructor(
    protected personalService: PersonalService,
    protected translateService: TranslateService,
    @Inject(Store) protected store: Store<Reducers>
  ) {}
  period: Period;
  auth: Auth;
  subcribe: Subscription;
  history: HistoryExamed;
  patient: Patient;
  ngOnInit() {
    this.subcribe = this.store
      .pipe(
        select((state) => ({
          history: state.history,
          auth: state.auth,
          period: state.period,
          patient: state.patient,
        }))
      )
      .subscribe(({ history, auth, period, patient }) => {
        this.auth = auth;
        this.period = period;
        this.patient = patient;
        if (history) {
          if (
            !(
              this.history &&
              this.history.patient_visit_id === history.patient_visit_id
            )
          ) {
            this.history = history;
            this.getMedicalHistory(history.patient_visit_id);
          }
        }
      });
  }

  ngOnDestroy() {
    this.subcribe.unsubscribe();
  }

  getMedicalHistory(pid: string) {
    this.isLoading = true;
    this.personalService
      .getMedicalHistory(pid, environment.key)
      .subscribe((response: any) => {
        if (response && response.json_history) {
          const { json_history } = response;
          try {
            const parse: any = JSON.parse(json_history);
            const { noi_khoa } = parse;
            const data: any = { ...parse };

            if (noi_khoa && _.isArray(noi_khoa)) {
              data.noi_khoa = new Set(noi_khoa.map((value) => value));
            } else {
              data.noi_khoa = new Set();
            }

            if (data.ho_so_tiem_chung) {
              if (data.ho_so_tiem_chung["hepatitis a"]) {
                data.ho_so_tiem_chung.hepatitisa =
                  data.ho_so_tiem_chung["hepatitis a"];
                data.ho_so_tiem_chung.hepatitisb =
                  data.ho_so_tiem_chung["hepatitis b"];
                data.ho_so_tiem_chung.typhoid_fever =
                  data.ho_so_tiem_chung["typhoid fever"];
              }
            }

            if (!data.thong_tin_benh_nhan) {
              data.thong_tin_benh_nhan = this.tien_su_y_khoa.thong_tin_benh_nhan;
            }
            this.store.dispatch(updatePatient(data.thong_tin_benh_nhan));
            //console.log("Du lieu", data.phu_khoa);
            let _tmpChanged = [];
            let _i = 0;
            for (let i of this.gynecological) {
              _i+=1;
              _tmpChanged.push({
                slug: i.slug,
                name: i.name,
                status: data.phu_khoa['pk'+_i] == "" ? true : false,
                value: data.phu_khoa['pk'+_i],
              });
              //console.log(_tmpChanged);
              //_i+=1;
            }
            this.gynecological = _tmpChanged;
            this.tien_su_y_khoa = data;
          } catch (error) {
            /** IGRONE */
            console.log("ERROR JSON", error);
          }
        }
        this.isLoading = false;
      });
  }

  onSubmit() {
    const data: any = { ...this.tien_su_y_khoa };
    if (data.flag_blocked) {
      return;
    }

    if (!data.thong_tin_benh_nhan) {
      data.thong_tin_benh_nhan = {};
    }

    if (this.patient) {
      data.thong_tin_benh_nhan = {
        cso: this.patient.cso,
        date_of_exam: this.patient.date_of_exam,
      };
    }

    data.noi_khoa = Array.from(this.tien_su_y_khoa.noi_khoa);

    const request = {
      patient_visit_id: this.history.patient_visit_id,
      json_history: JSON.stringify(data),
      username: this.auth.user_username,
      period: this.period.period,
      key: environment.key,
    };

    this.isSubmiting = true;

    this.personalService.setMedicalHistory(request).subscribe((response) => {
      if (response === "OK") {
        forkJoin([
          this.translateService.get("Success"),
          this.translateService.get("Update completed"),
        ]).subscribe(([success, updated]) => {
          Swal.fire(success, updated, "success");
        });
      } else {
        forkJoin([this.translateService.get("Error")]).subscribe(([error]) => {
          Swal.fire(error, "", "error");
        });
      }
      this.isSubmiting = false;
    });
  }

  handle_PhuKhoaCauHoi(type: any) {
    this.tien_su_y_khoa.phu_khoa_cau_hoi.tinh_trang = type;
    if (!type) {
      this.tien_su_y_khoa.phu_khoa_cau_hoi.phuong_phap = "";
    }
  }

  handle_TienSuNoiKhoa(item: any, e: any) {
    const { checked } = e.target;
    if (checked) {
      this.tien_su_y_khoa.noi_khoa.add(item.name);
    } else {
      this.tien_su_y_khoa.noi_khoa.delete(item.name);
    }
  }
  getBooleanToEmptyString(value: any) {
    return typeof value === "boolean" ? "" : value;
  }
  handle_TienSuNgoaiKhoa(e: any) {
    const { value } = e.target;
    this.tien_su_y_khoa.ngoai_khoa = value;
  }
  handle_TienSuPhuKhoa(item: any, e: any) {
    const { value, type, checked } = e.target;
    if (type === "checkbox" && checked) {
      this.tien_su_y_khoa.phu_khoa[item.slug] = true;
    } else {
      this.tien_su_y_khoa.phu_khoa[item.slug] = value;
    }
  }
  handle_ThoiQuenSinhHoat(name: string, type: string, e: any) {
    switch (type) {
      case "yes": {
        this.tien_su_y_khoa.thoi_quen_sinh_hoat[name].tinh_trang = true;
        break;
      }
      case "no": {
        this.tien_su_y_khoa.thoi_quen_sinh_hoat[name].tinh_trang = false;
        break;
      }
      default:
        const { value } = e.target;
        this.tien_su_y_khoa.thoi_quen_sinh_hoat[name][type] = value;
    }
  }
  handle_Vaccin(name: string, type: string) {
    this.tien_su_y_khoa.ho_so_tiem_chung[name].tinh_trang = type;
    if (this.tien_su_y_khoa.ho_so_tiem_chung[name].tinh_trang !== "yes") {
      this.tien_su_y_khoa.ho_so_tiem_chung[name].ngay_tiem_gan_nhat = "";
    }
  }

  isPrinting: boolean;

  print() {
    if (this.isPrinting) {
      return;
    }

    var source = window.document.getElementById("print-pdf");

    this.isPrinting = true;
    const width = $("#print-pdf").width();
    const height = $("#print-pdf").height();
    const opt: any = {
      width,
      height: height + 20,
      scrollX: 0,
      scrollY: window.pageYOffset * -1 + 20,
    };
    const canvasHTML: any = html2canvas;
    canvasHTML(source, opt).then((canvas: any) => {
      var img = canvas.toDataURL("image/png");
      var doc = new jsPDF();
      doc.addImage(img, "JPEG", 5, 5, 200, 230);
      doc.save(`${new Date().getTime()}.pdf`);
      this.isPrinting = false;
    });
  }
}
