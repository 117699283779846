import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {

  @Input() value: string = "";
  @Input() size: string = "sm";
  @Output() onChange = new EventEmitter();
  @Input() disabled: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  handleChange(event: any){
    const {value} = event.target;
    //console.log(value);
    this.onChange.emit(value);
  }

}
