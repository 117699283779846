import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Store} from '@ngrx/store';
import {Reducers} from 'src/app/reducers';
import {login} from 'src/app/reducers/Auth';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import Auth from 'src/app/models/Authenticate';
import {setCookie} from 'src/app/helpers/cookie';
import {COOKIE_AUTH} from 'src/app/Key';
import {PersonalService} from 'src/app/services/aih/personal-api.service';
import * as _ from 'lodash';
import {updatePatient} from 'src/app/reducers/Patient';
import {Patient} from 'src/app/models/aih/Patient.model';
import { LoginResponse } from 'src/app/models/Response';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  isValid = true;
  isLoading = false;
  remember = false;
  phone = '';

  @Output() onClickRememberMe = new EventEmitter();

  constructor(
    protected store: Store<Reducers>,
    @Inject(Router) protected router: Router,
    private translate: TranslateService,
    public auth: AuthService,
    public personalService: PersonalService,
  ) {
  }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {
    this.isValid = form.valid;
    // check form valid
    if (!this.isValid) {
      return;
    }
    const { account, password, remember } = form.value;
    this.auth.login(account, password).subscribe(({ ResponseMessage, StatusCode, profile, token }: LoginResponse) => {
      if (ResponseMessage) {
        this.authErrors(ResponseMessage);
      }
      if (profile) {
        profile.token = token;
        this.authSuccess(profile, remember);
      }
      this.isLoading = false;
    });
  }

  authSuccess(data: Auth, remember: boolean = false) {
    this.personalService.getPatients(data.user_username).subscribe(response => {
      if (response && _.isArray(response)) {
        setCookie('OTP_VERIFY', '')
        setCookie("OTP_VERIFIED", '');
        if (response.length >= 2) {
          return this.showSelectPatients(data, response, remember);
        } else if (response.length === 1) {
          return this.redirectToLoggedPage(data, response[0], remember);
        } else {
          return this.redirectToLoggedPage(data, null, remember);
        }
        
      }
    });
  }

  showSelectPatients(data: Auth, response: Patient[], remember = false) {
    this.translate.get([
      'please select patient',
      'please select a patient'
    ]).subscribe((messages) => {
      const patients = {};
      response.map((node, index) => {
        patients[index] = `(${node.visible_patient_id}) - ${this.translate.currentLang === 'vi' ? node.patient_name : node.patient_name_e}`;
      });
      return Swal.fire({
        title: messages['please select patient'],
        input: 'radio',
        customClass: {
          container: 'select-patient'
        },
        inputOptions: patients,
        icon: 'question',
        inputValidator: (value) => {
          if (!value) {
            return messages['please select a patient'];
          }
        }
      }).then(result => {
        if (result.value) {
          const patient = response[Number(result.value)];
          return this.redirectToLoggedPage(data, patient, remember);
        } else {
          // Đăng xuất nếu không chọn bệnh nhân
          return this.auth.logout(data.user_username, data.token);
          // return this.showSelectPatients(data, response, remember);
        }
      });
    });
  }

  /**
   * Chuyển hướng sang trang đã đăng nhập
   * @param data
   * @param patient
   * @param remember
   */
  redirectToLoggedPage(data: Auth, patient?: Patient, remember?) {
    // Lưu tài khoản khi tắt trình duyệt
    if (remember) {
      setCookie(COOKIE_AUTH, JSON.stringify(data));
    }

    if (patient) {
      // thông tin bệnh nhân
      this.store.dispatch(updatePatient(patient));
    }

    // thông tin tài khoản
    this.store.dispatch(login(data));

    // chuyển hướng sang trang đang nhập
    this.router.navigate(['/dashboard']);
  }

  authErrors(message: string) {
    forkJoin([
      this.translate.get('Error occurred'),
      this.translate.get(message),
      this.translate.get('text_close')
    ]).subscribe(([title, text, cancel]: string[]) => {
      Swal.fire({title, text, cancelButtonText: cancel});
    });
  }

  onClick() {
    this.onClickRememberMe.emit('remember');
  }

}
