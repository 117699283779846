import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Reducers } from 'src/app/reducers';
import Auth from 'src/app/models/Authenticate';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit {


  routes = [
    { role: 2, name: "Overview", routerLink: "/dashboard" },
    { role: 2, name: "Appoinment", routerLink: "/dashboard/appoinment" },
    { role: 2, name: "GUIDE", routerLink: "/dashboard/guids" },

    { role: 3, name: "CONFIRMATION OF HEALTH CHECK APPOINTMENT", routerLink: "/dashboard/confirm" },
    { role: 3, name: "HEALTH CHECK RESULTS", routerLink: "/dashboard/result" },
    { role: 3, name: "GUIDE", routerLink: "/dashboard/guids" },
  ];

  auth: Auth;

  constructor(
    protected store: Store<Reducers>
  ) { }

  ngOnInit() {
    this.store.pipe(select("auth")).subscribe(values => {
      this.auth = values;
      //console.log(values);
    })
  }

  handleClick() {
    const btn: any = document.querySelector(".navbar-toggler");
    btn.click();
  }

}
