import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';

@Component({
  selector: 'app-counter-anim',
  templateUrl: './counter-anim.component.html',
  styleUrls: ['./counter-anim.component.scss']
})
export class CounterAnimComponent implements OnDestroy, OnChanges {
  @Input() counter: number = 0;
  
  count = 0;
  interval: any;
  constructor() { }
  ngOnChanges({counter}: any){
    // this.remove();
    // this.update(counter.currentValue);
  }
  ngOnDestroy(){
    this.remove();
  }

  remove(){
    if(this.interval){
      clearInterval(this.interval);
    }
  }

  update(counter: number){
    this.interval = setInterval(() => {
      if(this.count < counter){
        this.count++;
      }else{
        clearInterval(this.interval);
      }
    }, 2);
  }

}
