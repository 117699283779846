import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-last-hcresult',
  templateUrl: './last-hcresult.component.html',
  styleUrls: ['./last-hcresult.component.scss']
})
export class LastHCResultComponent implements OnInit {

  isLoading = false;

  constructor() { }

  ngOnInit() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

}
