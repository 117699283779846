import { Component, LOCALE_ID, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import Auth from './models/Authenticate';
import { getLanguage } from './utilities';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { getQueryValue } from "./helpers/query";
import { setLanguage } from 'src/app/utilities';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'AIH Health Check';

  isBrowser: boolean;

  protected auth: Observable<null | Auth>;

  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    @Inject(PLATFORM_ID) protected platformId: string,
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,
  ) {
  }

  ngOnInit() {
    this.translate.addLangs(["vi", "en"]);
    let lang = getQueryValue("lang");
    if (lang) {
      this.translate.use(lang);
      if (isPlatformBrowser(this.platformId)) {
        setLanguage(lang);
      }
    } else {
      this.isBrowser = isPlatformBrowser(this.platformId);
      lang = 'en';
      // this language will be used as a fallback when a translation isn't found in the current language
      this.translate.setDefaultLang(lang);
      // the lang to use, if the lang isn't available, it will use the current loader to get them
      if (this.isBrowser) {
        this.translate.use(getLanguage());
      } else {
        lang = 'en';
        this.translate.use('vi'); // Activate current language or default language
      }
    }
    this.document.documentElement.lang = lang;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.document.documentElement.lang = event.lang;
    })
  }
}
