import { Component, OnInit, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PersonalService } from 'src/app/services/aih/personal-api.service';
import { Store, select } from '@ngrx/store';
import { Reducers } from 'src/app/reducers';
import { environment } from 'src/environments/environment';
import * as _ from "lodash";
import { isArray } from 'lodash';
import * as $ from "jquery";
import { Unsubscribable } from 'rxjs';
import { HistoryExamed } from 'src/app/models/HistoryExamed';
import { Patient } from 'src/app/models/aih/Patient.model';
import { isPlatformBrowser } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-result-mecial',
  templateUrl: './result-mecial.component.html',
  styleUrls: ['./result-mecial.component.scss']
})
export class ResultMecialComponent implements OnInit, OnDestroy {

  public isMobile;
  public isTablet;
  public isDesktopDevice;
  private deviceInfo = null;
  isLoading = false;
  history: HistoryExamed;
  verifiedOtp = false;
  profile: null | Patient = null;
  hasResult = false;

  constructor(
    @Inject(TranslateService) protected translate: TranslateService,
    @Inject(PersonalService) protected personalService: PersonalService,
    protected store: Store<Reducers>,
    @Inject(PLATFORM_ID) private platformId,
    private deviceService: DeviceDetectorService,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.checkDevice();
    }
  }

  checkDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    // console.log(this.deviceInfo);
    // console.log('isMobile', this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    // console.log('isTablet', this.isTablet);  // returns if the device us a tablet (iPad etc)
    // console.log('isDesktopDevice', this.isDesktopDevice); // returns if the app is running on a Desktop browser.
  }
  ngOnDestroy() {
    $("#logged-page-content").removeClass("medical-result");
    this.subscribe.unsubscribe();
  }


  getResults() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.personalService.getListFileResult(this.history.patient_visit_id, environment.key).subscribe((results) => {
      if (results && isArray(results) && results.length) {
        // console.log(results);
        results.map((node) => {
          const { patient_visit_id, url_file } = node;
          const url = `${environment.aih_api}/UploadedFiles/HCCDocuments/${this.profile.visible_patient_id}/${patient_visit_id}${url_file}`;
          if (/REPORT/.test(url_file.toUpperCase())) {
            this.ket_qua_1 = url;
          } else {
            this.ket_qua_2 = url;
          }
        });
        this.hasResult = true;
      }
      this.isLoading = false;
    });
  }

  ket_qua_1: string;
  ket_qua_2: string;
  subscribe: Unsubscribable;
  ngOnInit() {
    $("#logged-page-content").addClass("medical-result");
    this.subscribe = this.store.pipe(select(state => ({
      patient: state.patient,
      history: state.history
    }))).subscribe(({ patient, history }) => {
      if (history && patient) {
        this.history = history;
        this.profile = patient;
        this.getResults();
      }
    });
  }

}
