import {Injectable} from '@angular/core';
import {RestApiService} from './rest-api.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AuthService {
  constructor(private http: RestApiService, protected translate: TranslateService) {
  }

  public getCurrentLocal() {
    const dict = {
      en: 'en-US',
      vi: 'vi-VN'
    };
    const lang = this.translate.currentLang;
    return dict[lang] ? dict[lang] : lang;
  }

  public login(username: string, password: string) {
    return this.http.post('user/login', {
      user_username: username,
      user_password: password
    });
  }

  public changePassword(user_username: string, password: string, new_password: string) {
    return this.http.post('user/change-password', {
      'user_username': user_username,
      'token': 'epdE24J010jYULhtx6sWTpcaIZQvOkkg',
      'user_password': password,
      'user_password_new': new_password
    });
  }

  public logout(user_username: string, token: string) {
    return this.http.post('user/login', {user_username, token});
  }

  public reset(username: string) {
    const ResetPwdKey = 'V9eQfRzTBRdkED2PVy5ehVgym2zeDgns';
    return this.http.post('user/new-password', {
      'key': ResetPwdKey,
      'user_username': username,
      'lang': this.getCurrentLocal()
    });
  }

}
