import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {OtpResponse, OtpService} from 'src/app/services/otp.service';
import {forkJoin, Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {getCookie, setCookie} from '../helpers/cookie';
import {select, Store} from '@ngrx/store';
import {Reducers} from '../reducers';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit, OnDestroy {
  isSendedOTP = false;
  otpResponse: null | OtpResponse = null;
  code = '';
  verifying = false;
  delay = 0;
  intervalTimeDelay: any;
  phone: string = '';
  isLoading = false;
  subscribe: Subscription;

  @Output() success = new EventEmitter();

  constructor(
    private readonly otpService: OtpService,
    private translate: TranslateService,
    protected store: Store<Reducers>,
  ) {
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  ngOnInit() {
    const otpResponded = getCookie('OTP_VERIFY');
    const isVerified = getCookie('OTP_VERIFIED');
    if (isVerified === '1') {
      this.success.emit();
    } else {
      if (otpResponded) {
        try {
          this.otpResponse = JSON.parse(otpResponded);
        } catch (error) {
        }
      }
    }
    this.subscribe = this.store.pipe(
      select(state => ({
        auth: state.auth
      }))
    ).subscribe(({auth}) => {
      this.phone = auth.user_username;
    });
  }


  sendOTP() {
    if (this.isSendedOTP && this.code.length === 4) {
      this.verfiryOTP(this.code);
    } else {
      this.sendOTPToPhone(this.phone);
    }
  }


  resendOTP() {
    this.sendOTPToPhone(this.phone);
  }

  onOtpChange(otp: string) {
    this.code = otp;
    if (this.code.trim().length === 4) {
      this.verfiryOTP(this.code);
    }
  }

  calculateTimeDelay(expires: string) {
    const date = new Date(expires);
    const now = new Date().getTime();
    const total = date.getTime() - now;
    this.delay = Math.round(total / 1000);
  }

  timeDelay(expires: string) {
    this.calculateTimeDelay(expires);
    this.intervalTimeDelay = setInterval(() => {
      this.calculateTimeDelay(expires);
      if (this.delay <= 0) {
        clearInterval(this.intervalTimeDelay);
      }
    }, 1000);
  }

  sendOTPToPhone(phone: string | number) {
    this.isLoading = true;
    this.otpService.sendOTPToPhone(phone).subscribe((result: OtpResponse) => {
      this.isLoading = false;
      if (result.is_sent && result.StatusCode) {
        this.otpResponse = result;
        setCookie('OTP_VERIFY', JSON.stringify(result));
        this.timeDelay(this.otpResponse.expiry_date);
        this.isSendedOTP = true;
      }
    });
  }

  verfiryOTP(code: string) {
    this.verifying = true;
    this.otpService.verfiryOTP({
      mobile: this.phone,
      validation_id: this.otpResponse.validation_id,
      token: code
    }).subscribe(({ResponseMessage, profile, token}: any) => {
      this.verifying = false;
      if (ResponseMessage) {
        forkJoin([
          this.translate.get('Error occurred'),
          this.translate.get(ResponseMessage),
          this.translate.get('text_close')
        ]).subscribe(([title, text, cancel]: string[]) => {
          Swal.fire({title, text, cancelButtonText: cancel});
        });
      }
      if (profile) {
        profile.token = token;
        setCookie('OTP_VERIFIED', '1', 1);
        this.success.emit(profile);
      }
    });
  }

}
