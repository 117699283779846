import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-render-pdf',
  templateUrl: './render-pdf.component.html',
  styleUrls: ['./render-pdf.component.scss']
})
export class RenderPDFComponent implements OnInit, OnChanges {

  @ViewChild('canvas', { static: true }) canvas: ElementRef;

  @Input() url: string;

  constructor() { }

  ngOnChanges() {
    if (this.url) {
      this.renderPDF(this.url);
    }
  }

  renderPDF(url: string) {
    console.log(url);
    // Loaded via <script> tag, create shortcut to access PDF.js exports.
    var pdfjsLib = window['pdfjs-dist/build/pdf'];

    // The workerSrc property shall be specified.
    pdfjsLib.GlobalWorkerOptions.workerSrc = '/assets/pdf/pdf.worker.js';

    // Asynchronous download of PDF
    var loadingTask = pdfjsLib.getDocument(url);
    loadingTask.promise.then((pdf) => {
      console.log('PDF loaded', pdf.numPages);

      // Fetch the first page
      var pageNumber = 1;
      var currentPage = 1;
      var pages = [];

      const renderPage = (page) => {
        var scale = 1.5;
        var viewport = page.getViewport(scale);
        if (viewport) {

          // Prepare canvas using PDF page dimensions
          var canvas: any = document.createElement('canvas');
          canvas.className = "col-12 col-md-6";
          var context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          // Render PDF page into canvas context
          var renderContext = {
            canvasContext: context,
            viewport: viewport
          };
          page
            .render(renderContext)
            .then( () => {
              pages[currentPage] = canvas;
              if (currentPage < pdf.numPages) {
                currentPage++;
                return pdf.getPage(currentPage).then(renderPage);
              }
              for (var i = 1; i < pages.length; i++) {
                this.canvas.nativeElement.appendChild(pages[i]);
              }
              console.log('Page rendered', pages);
            });
        } else {
          console.log("Cannot load viewport");
        }
      }

      pdf.getPage(pageNumber).then(renderPage);
    }, function (reason) {
      // PDF loading error
      console.error(reason);
    });
  }

  ngOnInit() {
  }

}
