import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { Appoinment } from 'src/app/models/Appoinment.model';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import * as moment from "moment";
import { CompanyService } from 'src/app/services/aih/company-api.service';
import { Page } from 'src/app/models/aih/Page.model';
import { Store, select } from '@ngrx/store';
import { Reducers } from 'src/app/reducers';
import { Period } from 'src/app/reducers/Period';
import { Subscription, from } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { isObject, isArray } from "lodash";
import { environment } from 'src/environments/environment';
import { toDateFormat } from 'src/app/utilities';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { PackageModel } from 'src/app/models/aih/Package.model';
import Swal from 'sweetalert2';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-appoinment',
  templateUrl: './appoinment.component.html',
  styleUrls: ['./appoinment.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AppoinmentComponent implements OnInit, OnDestroy {
  isLoading = false;
  appoinments: null[] | Appoinment[] = [null, null, null, null, null, null, null, null, null, null];
  today = new Date();
  page: number = 1;
  limit: number = 10;
  totalPages: number = 0;
  fromDate: string = "";
  toDate: string = "";
  period: PackageModel | null = null;
  filter: string = "";
  unSubscire: Subscription;

  min: NgbDate;
  max: NgbDate;
  disabledCalendar = false;
  clickedBtnSearch = false;

  constructor(
    public companyService: CompanyService,
    public translate: TranslateService,
    @Inject(Store) public store: Store<Reducers>
  ) { }

  ngOnDestroy() {
    this.unSubscire.unsubscribe();
  }

  ngOnInit() {
    this.unSubscire = this.store.pipe(select("period")).subscribe((values: Period) => {
      if (values) {
        const { period, fromDate, toDate } = values;
        this.period = period;

        const now = new Date();
        const time = now.getTime();
        let min: any = new Date(period.start_date).getTime();
        let max: any = new Date(period.end_date).getTime();

        if (max < time) {
          this.disabledCalendar = true;
        }

        min = new Date(min < time ? time : min);
        max = new Date(max > time ? max : time);

        this.min = new NgbDate(min.getFullYear(), min.getMonth() + 1, min.getDate());
        this.max = new NgbDate(max.getFullYear(), max.getMonth() + 1, max.getDate());

        this.fromDate = fromDate ? toDateFormat(fromDate) : "";
        this.toDate = toDate ? toDateFormat(toDate) : "";

        this.getAppoinments();
      }
    });
  }

  onUploadSuccess() {
    this.getAppoinments();
  }

  getStatus(item: Appoinment) {
    if (item.closed_date_time && !item.checkin_date && !item.date_of_exam) {
      return "Từ chối khám";
    } else if (item.flag_closed) {
      return "Đã có kết quả";
    } else if (item.checkin_date) {
      return "Đã khám";
    } else if (item.appointment_date) {
      return "Đã đăng ký";
    } else {
      return "Chưa đăng ký";
    }
  }

  /**
   * Disabled cho phép đặt lịch hẹn
   * @param item
   */
  isDisabled(item: Appoinment) {
    if (this.disabledCalendar) {
      return true;
    }

    if (item.appointment_date) {
      const date = moment(item.appointment_date).isoWeekday();
      if (date === 6) {
        return true;
      }
    }
    /**
     * Đã đăng ký ngày: checkin_date
     * Đã có kết quả: flag_closed
     * Đã khám: closed_date_time
     */
    return item.checkin_date || item.flag_closed || item.closed_date_time;
  }

  /**
   * Cho phép cancel lịch hẹn hay không
   * @param item
   */
  isAcceptCancel(item: Appoinment) {
    /**
     * Đã khám
     * Đã có kết quả
     * Đã đóng
     */
    if (item.flag_closed || item.closed_date_time || item.checkin_date) {
      return false;
    }

    if (!item.hideBookingForm) {
      return false;
    }

    /**
     * Đã đăng ký thì cho hủy lịch
     */
    if (item.appointment_date) {
      return true;
      // const d = new Date(item.appointment_date);
      // if (d.getTime() >= this.today.getTime()) {
      //   return true;
      // }
    }
  }

  onBlurSearch() {
    if (!this.clickedBtnSearch) {
      this.page = 1;
      this.getAppoinments();
    }
  }

  onChangeSearchField($event) {
    this.filter = $event.target.value;
    this.clickedBtnSearch = false;
  }

  onCancelAppointment(i: number, status: boolean) {
    if (status === true) {
      this.appoinments[i].hideBookingForm = false;
      this.appoinments[i].date = "";
      this.appoinments[i].status = "Chưa đăng ký";
      if (!this.appoinments[i].collection_date_time) {
        //this.appoinments[i].time = "AM";
        this.appoinments[i].time = "";
      } else {
        this.appoinments[i].time = "";
      }
      this.appoinments[i].appointment_date = "";
    }
  }

  /**
   * Lấy danh sách đặt lịch hẹn
   */
  getAppoinments() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    const args = {
      "page": this.page,
      "pageSize": this.limit,
      "filter": this.filter,
      "key": environment.key,
      "period": this.period.package_code,
      "fromDate": this.fromDate,
      "toDate": this.toDate
    };
    this.companyService.getListAppointment(args).subscribe((response: Page<Appoinment>) => {
      if (response && isObject(response)) {
        const { Items, PageIndex, TotalPages, PageSize } = response;
        if (isArray(Items)) {
          this.totalPages = TotalPages * PageSize;
          this.page = PageIndex;
          this.appoinments = Items.map((item: Appoinment) => {
            item.status = this.getStatus(item);
            if (item.appointment_date) {
              item.date = moment(item.appointment_date).format("YYYY-MM-DD");
              item.time = moment(item.appointment_date).hour() < 13 ? "AM" : "PM";
              item.hideBookingForm = true;
            } else {
              /**
               * Cập nhật collection_date_tim = null thì không cho phép chọn AM / PM
               * Mặc định khi = null là AM
               */
              // if (!item.collection_date_time) {
              //   item.time = "AM";
              // } else {
              //   item.time = "";
              // }
              if (!item.collection_date_time) {
                item.time = "";
              }
            }
            // console.log(item);
            return item;
          });
          // console.log(this.appoinments);
        }
      }
      this.isLoading = false;
    });
  }

  dateOfBirth(date: string) {
    return moment(date).format("DD-MM-YYYY");
  }

  isDisabledTime(item: Appoinment) {
    return item.collection_date_time;
    // return true;
  }

  onPageChange(page: number) {
    this.page = page;
    this.getAppoinments();
  }

  changeDate(i: number, $event: any) {
    if ($event) {
      // console.log(this.appoinments[i]);

      const appointment_date = toDateFormat($event);
      const date = moment(appointment_date).isoWeekday();
      if (date === 6) {
        this.appoinments[i].time = "AM";
      }
      this.appoinments[i].appointment_date = appointment_date;
      this.setApointment(i, this.appoinments[i]);
    }
  }

  onChangeTime(i: number, time: any) {
    const date = moment(this.appoinments[i].appointment_date).isoWeekday();
    if (date === 6) {
      this.appoinments[i].time = "AM";
    } else {
      this.appoinments[i].time = time;
    }
    this.setApointment(i, this.appoinments[i]);
  }

  setApointment(i: number, item: Appoinment) {
    const { appointment_date, time, patient_visit_id } = item;
    console.log(item);

    if (appointment_date && time) {
      const data = {
        "patient_visit_id": patient_visit_id,
        "set_date": appointment_date,
        "period": this.period.package_code,
        "shift": time,
        "key": environment.key
      };
      this.appoinments[i].isUpdating = true;
      this.companyService.setAppointment(data).subscribe(response => {
        if (response === "OK") {
          this.appoinments[i].date = moment(item.appointment_date).format("YYYY-MM-DD");
          this.appoinments[i].status = this.getStatus(item);
          this.appoinments[i].hideBookingForm = true;
        } else {
          this.translate.get("confirm_exam_error").subscribe(value => {
            Swal.fire(value, "", "error");
          });
        }
        this.appoinments[i].isUpdating = false;
      });
    }
  }

  handleSearch(e: any) {
    e.preventDefault();
    this.page = 1;
    this.clickedBtnSearch = true;
    this.getAppoinments();
  }
}
