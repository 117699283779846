import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {

  @Output() close = new EventEmitter()

  constructor(
    protected translate: TranslateService
  ) { }

  ngOnInit() {
  }

  onSuccess() {
    this.close.emit();
  }
}
