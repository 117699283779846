import { Component, OnInit, Inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Reducers } from 'src/app/reducers';
import { PersonalService } from 'src/app/services/aih/personal-api.service';
import { HistoryExamed } from 'src/app/models/HistoryExamed';
import { addHistoryExamed } from 'src/app/reducers/HistoryExamed';
import * as moment from "moment";
import { selectHistory } from 'src/app/reducers/History';
import { isArray } from "lodash";

@Component({
  selector: 'app-select-medical-history',
  templateUrl: './select-medical-history.component.html',
  styleUrls: ['./select-medical-history.component.scss']
})
export class SelectMedicalHistoryComponent implements OnInit {

  @Input() size: string = "";

  isLoading: boolean = false;
  selected: string = "";
  data: HistoryExamed[] = [];

  constructor(
    @Inject(Store) protected store: Store<Reducers>,
    protected personalService: PersonalService
  ) { }

  ngOnInit() {
    this.store.select(state => ({
      auth: state.auth,
      patient: state.patient
    })).subscribe(({auth, patient}) => {
      if(auth && patient && !this.isLoading && !this.selected){
        this.getUserProfile(auth.user_username, patient.patient_id);
      }
    });
  }

  handleChange($event){
    this.selected = $event.target.value;
    const data = this.data.find(node => node.patient_visit_id === $event.target.value);
    this.store.dispatch(selectHistory(data));
    // console.log(data);
  }

  onSubmit(e: any) {
    const data: null | HistoryExamed = this.data.find(node => node.patient_visit_id === this.selected);
    if(data){
      this.store.dispatch(selectHistory(data));
    }
  }

  getDate(str: string){
    return moment(str).format("DD-MM-YYYY");
  }

  /**
   * Lấy thông tin bệnh nhân, nếu đây là tài khoản cá nhân
   */
  getUserProfile(username: string, pid: string) {
    this.isLoading = true;
    this.personalService.getListHistoryExamed(username, pid).subscribe((data: any | HistoryExamed[]) => {

      if(isArray(data) && data.length){
        this.data = data.sort((a, b) => moment(b.date_of_exam).toDate().getTime() -  moment(a.date_of_exam).toDate().getTime() );
        this.selected = data[0].patient_visit_id;
        this.store.dispatch(selectHistory(data[0]));
        this.store.dispatch(addHistoryExamed(data));
      }
      this.isLoading = false;

    });
  }

}
