import { Action } from '@ngrx/store';
import { PackageModel } from '../models/aih/Package.model';

export const ADD_PACKAGES = "ADD_PACKAGES";
export const DEL_PACKAGES = "DEL_PACKAGES";

export const addPackages = (packages: PackageModel[]) => ({ type: ADD_PACKAGES, packages });
export const removePackages = () => ({ type: DEL_PACKAGES });

interface PackageAction extends Action {
    packages: PackageModel[];
}

export function packagesReducer(state = [], action: PackageAction) {
    // console.log(action);
    switch (action.type) {
        case ADD_PACKAGES:
            return action.packages;
        case DEL_PACKAGES:
            return [];
        default:
            return state;
    }
}
