import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Reducers } from 'src/app/reducers';
import Auth from 'src/app/models/Authenticate';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Patient } from 'src/app/models/aih/Patient.model';
import { PackageModel } from 'src/app/models/aih/Package.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  logo = window.innerWidth <= 768 ? window._INIT_DATA_.logo : window._INIT_DATA_.logo_blue;
  isLogged = false;
  auth: Auth;
  isOpen = false;
  patient: Patient;
  package: PackageModel;

  constructor(
    protected store: Store<Reducers>,
    protected authService: AuthService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.store.pipe(select(state => ({
      auth: state.auth,
      patient: state.patient,
      period: state.period
    }))).pipe().subscribe(({ auth, patient, period }) => {
      if (auth) {
        this.auth = auth;
        this.isLogged = true;
        this.logo = window._INIT_DATA_.logo;
      }
      if (patient && !this.patient) {
        this.patient = patient;
      }
      if (period && !this.package) {
        this.package = period.period;
      }
    });
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  closeModal(){
    $('button[data-dismiss="modal"]').trigger("click")
  }

}
