import { Component, OnInit, Inject } from "@angular/core";
import { PersonalService } from "src/app/services/aih/personal-api.service";
import { isObject } from "lodash";
import { Store, select } from "@ngrx/store";
import { Reducers } from "src/app/reducers";
import { environment } from "src/environments/environment";
import { HistoryExamed } from "src/app/models/HistoryExamed";
import { isArray } from "lodash";
import * as moment from "moment";
import { toDateFormat } from "src/app/utilities";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { CompanyService } from "src/app/services/aih/company-api.service";
import { addPackages } from "src/app/reducers/Packages";
import { PackageModel } from "src/app/models/aih/Package.model";
import Swal from "sweetalert2";
import { addPeriod } from "src/app/reducers/Period";
import { forkJoin } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-form-confirm-exam",
  templateUrl: "./form-confirm-exam.component.html",
  styleUrls: ["./form-confirm-exam.component.scss"],
})
export class FormConfirmExamComponent implements OnInit {
  period: string = "";
  package_code: string = "";
  appointment_date: null | string = null;
  isLoading = false;
  // histories: HistoryExamed[];
  date: string = "";
  shift: string = "";
  history: HistoryExamed;
  packages: PackageModel[];
  disabled: boolean = false;
  disabled_cancel: boolean = false;
  patient_visit_id: string;
  periodIndvidual: any = null;
  disabledShift: boolean = false;
  todayDate = new Date();
  today = new NgbDate(
    this.todayDate.getFullYear(),
    this.todayDate.getMonth() + 1,
    this.todayDate.getDate()
  );

  constructor(
    protected personalService: PersonalService,
    protected companyService: CompanyService,
    private translate: TranslateService,
    @Inject(Store) protected store: Store<Reducers>
  ) { }

  getDate(str: string) {
    return moment(str).format("YYYY-MM-DD");
  }

  handleChangeDate(value: NgbDate) {
    if (value != null) {
      const date = toDateFormat(value);
      const day = moment(date).isoWeekday();
      if (value && day < 7) {
        this.date = date;
        this.disabledShift = day === 6;
        if (day === 6) {
          this.shift = "AM";
        }
      }
    } else {
    }
  }

  isFetchingCollection = false;
  isFetchingPeriod = false;

  ngOnInit() {
    this.store.pipe(select("history")).subscribe((history) => {
      if (history) {
        if (!this.isLoading) {
          this.isLoading = true;
          this.history = history;
          this.personalService
            .getAppointMent(history.patient_visit_id, environment.key)
            .subscribe((response: any) => {
              if (response && isObject(response)) {
                const {
                  appointment_date,
                  period,
                  patient_visit_id
                }: any = response;
                this.patient_visit_id = patient_visit_id;
                /**
                 * Nếu appointment_date != null => Đã đặt lịch khám,
                 * => Disabled = true => không cho đặt lịch
                 * Để đặt lịch, khách hàng phải cancel => appointment_date = null
                 */
                if (appointment_date) {
                  this.appointment_date = appointment_date;
                  const d = new Date(appointment_date);
                  this.date = moment(appointment_date).format("DD-MM-YYYY");
                  this.shift = d.getHours() < 13 ? "AM" : "PM";
                  this.disabled = true;
                }

                this.period = period;
              }
              this.isLoading = false;
            });
        }
        if (!this.isFetchingCollection) {
          this.isFetchingCollection = true;
          this.personalService
            .getCollection(history.patient_visit_id, environment.key)
            .subscribe((response: any) => {
              // console.log('Test', response);
              this.isFetchingCollection = false;
              // if (response && !response.collection_date_time) {
              // this.shift = "AM";
              // this.disabledShift = true;
              // }
              if ((response && response.flag_closed) || (response && response.checkin_date)) {
                this.disabled = true;
              }
              if (response && response.checkin_date) {
                this.disabled_cancel = true;
              }
            });
        }
        if (!this.isFetchingPeriod) {
          this.isFetchingPeriod = true;
          this.personalService
            .getPeriodIndvidual(history.patient_visit_id, environment.key)
            .subscribe((repsonse: any) => {
              this.isFetchingPeriod = false;
              if (repsonse) {
                this.periodIndvidual = {
                  start_date: null,
                  end_date: null,
                };
                const { start_date, end_date, package_code, period } = repsonse;

                const nowTimes = new Date();

                if (period) {
                  this.period = period;
                }

                if (package_code) {
                  this.package_code = package_code;
                }

                if (start_date) {
                  let date = new Date(start_date);
                  /**
                   * Nâng ngày bắt đầu được đặt lịch khám về tương lai nếu ngày bắt đầu nhỏ
                   * hơn ngày hôm nay, tức là ngày bắt đầu được đặt lịch là 01/06, nhưng hôm nay
                   * là 05/06 thì cho phép bắt đầu đặt lịch vào ngày mai
                   */
                  if (date.getTime() < nowTimes.getTime()) {
                    date = nowTimes;
                  }
                  this.periodIndvidual.start_date = new NgbDate(
                    date.getFullYear(),
                    date.getMonth() + 1,
                    date.getDate()
                  );
                }

                if (end_date) {
                  let date = new Date(end_date);
                  /**
                   * Ngược với ngày bắt đầu, nếu ngày bắt đầu nhỏ hơn ngày hôm nay thì không cho đặt nữa vì đã quá hạn
                   */
                  if (date.getTime() < nowTimes.getTime()) {
                    this.disabled = true;
                  }
                  this.periodIndvidual.end_date = new NgbDate(
                    date.getFullYear(),
                    date.getMonth() + 1,
                    date.getDate()
                  );
                }

                this.store.dispatch(
                  addPeriod(
                    period,
                    this.periodIndvidual.start_date,
                    this.periodIndvidual.end_date
                  )
                );
                // console.log(this.periodIndvidual);
              }
            });
        }
      }
    });
  }

  onCancel(canceled: boolean) {
    if (canceled) {
      this.date = "";
      if (this.disabledShift) {
        this.shift = "AM";
      } else {
        this.shift = "";
      }
      this.disabled = false;
      this.appointment_date = "";
    }
  }

  onChangeTime(e: any) {
    this.shift = e;
  }

  onSubmit() {
    forkJoin([
      this.translate.get("text_booking_success"),
      this.translate.get("confirm_exam_error"),
    ]).subscribe(([success, error]: string[]) => {
      const data = {
        patient_visit_id: this.history.patient_visit_id,
        set_date: this.date,
        period: this.package_code,
        shift: this.shift,
        key: environment.key,
      };
      this.companyService.setAppointment(data).subscribe((response) => {
        if (response === "OK") {
          this.appointment_date = new Date().toISOString();
          this.disabled = true;
          Swal.fire(success, "", "success");
        } else {
          Swal.fire(error, "", "warning");
        }
      });
    });
  }
}
