import {BrowserModule} from "@angular/platform-browser";
import {LOCALE_ID, NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import {TranslateLoader, TranslateModule, TranslateService,} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TransferHttpCacheModule} from "@nguniversal/common";

import {AppComponent} from "./app.component";
import {LoginComponent} from "./components/login/login.component";
import {AppRoutingModule} from "./app-routing.module";
import {ForgotPasswordComponent} from "./components/forgot-password/forgot-password.component";
import {HeaderComponent} from "./components/header/header.component";
import {LanguagesComponent} from "./components/languages/languages.component";
import {SlideshowModule} from "ng-simple-slideshow";
import {LoginFormComponent} from "./components/login-form/login-form.component";
import {FooterComponent} from "./components/footer/footer.component";
import {LoggedPageComponent} from "./components/logged-page/logged-page.component";
import {StoreModule} from "@ngrx/store";
import {OverviewsComponent} from "./components/overviews/overviews.component";
import {UserGuidsComponent} from "./components/user-guids/user-guids.component";
import {GuildDetailComponent} from "./components/guild-detail/guild-detail.component";
import {AppoinmentComponent} from "./components/appoinment/appoinment.component";
import {SkeletonComponent} from "./components/skeleton/skeleton.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgbDatepickerModule, NgbModule,} from "@ng-bootstrap/ng-bootstrap";
import {DatePickerComponent} from "./components/date-picker/date-picker.component";
import {FormPeriodComponent} from "./components/form-period/form-period.component";
import {PatientInfomationComponent} from "./components/patient-infomation/patient-infomation.component";
import {MedicalHistoryComponent} from "./components/medical-history/medical-history.component";
import {DashboardHeaderComponent} from "./components/dashboard-header/dashboard-header.component";
import {PatientFormComponent} from "./components/patient-form/patient-form.component";
import {ResultMecialComponent} from "./components/result-mecial/result-mecial.component";
import {PatientResultComponent} from "./components/patient-result/patient-result.component";
import {LastHCResultComponent} from "./components/last-hcresult/last-hcresult.component";

import {BookingComponent} from "./components/booking/booking.component";
import {BookingBaseComponent} from "./components/booking-base/booking-base.component";
import {BookingDateComponent} from "./components/booking-date/booking-date.component";
import {BookingDoctorComponent} from "./components/booking-doctor/booking-doctor.component";
import {BookingHomeComponent} from "./components/booking-home/booking-home.component";
import {BookingMobileComponent} from "./components/booking-mobile/booking-mobile.component";
import {BookingPhoneNumberComponent} from "./components/booking-phone-number/booking-phone-number.component";
import {BookingSpecialtyComponent} from "./components/booking-specialty/booking-specialty.component";
import {BookingTimeComponent} from "./components/booking-time/booking-time.component";
import {DoctorListItemComponent} from "./components/booking-doctor/doctor-list-item/doctor-list-item.component";

import {HttpClient, HttpClientModule} from "@angular/common/http";
import {SafeHtmlPipe} from "./shared/safe-html-pipe";
import {DecodeHtmlEntitiesModule} from "decode-html-entities";
import {BookingService} from "./services/booking.service";
import {ClinicService} from "./services/clinic.service";
import {DoctorService} from "./services/doctor.service";
import {AuthService} from "./services/auth.service";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {ChangePasswordComponent} from "./components/change-password/change-password.component";
import {LogoutBtnComponent} from "./components/logout-btn/logout-btn.component";
import {CompanyService} from "./services/aih/company-api.service";
import {periodReducer} from "./reducers/Period";
import {authReducer} from "./reducers/Auth";
import {FromDateToDateComponent} from "./components/from-date-to-date/from-date-to-date.component";
import {ClickOutsideModule} from "ng-click-outside";
import {PersonalService} from "./services/aih/personal-api.service";
import {CounterAnimComponent} from "./components/counter-anim/counter-anim.component";
import {SelectMedicalHistoryComponent} from "./components/select-medical-history/select-medical-history.component";
import {FormConfirmExamComponent} from "./components/form-confirm-exam/form-confirm-exam.component";
import {packagesReducer} from "./reducers/Packages";
import {CountUpModule} from "countup.js-angular2";
import {registerLocaleData} from "@angular/common";
import localeVi from "@angular/common/locales/vi";
import localeEn from "@angular/common/locales/en";
import {BtnUploadAppointmentComponent} from "./components/btn-upload-appointment/btn-upload-appointment.component";
import {historyExamedReducer} from "./reducers/HistoryExamed";
import {historyReducer} from "./reducers/History";
import {patientReducer} from "./reducers/Patient";
import {BtnCancelAppointmentComponent} from "./components/btn-cancel-appointment/btn-cancel-appointment.component";
import {RegisterComponent} from "./components/register/register.component";
import {GuidService} from "./services/guid.service";
import {BtnExportHcComponent} from "./components/btn-export-hc/btn-export-hc.component";
import {AihApiService} from "./services/aih/rest-api.service";
import {ChangePasswordModalComponent} from "./components/change-password-modal/change-password-modal.component";
import {RenderPDFComponent} from "./components/render-pdf/render-pdf.component";
import {PopupPostComponent} from "./components/popup-post/popup-post.component";
import {BaoMatComponent} from "./components/bao-mat/bao-mat.component";
import {HCComponent} from "./print/hc/hc.component";
import {NgxQRCodeModule} from "ngx-qrcode2";
import {SectionBookingHomeComponent} from './components/section/section-booking-home/section-booking-home.component';
import {SectionBookingMbComponent} from './components/section/section-booking-mb/section-booking-mb.component';
import {CustomerRegisterComponent} from './components/popup/customer-register/customer-register.component';
import {MyBookingComponent} from './components/my-booking/booking/booking.component';
import {UrlService} from './services/url.service';
import {RouteFactoryService} from './services/route-factory.service';
import {SuccessComponent} from './components/popup/success/success.component';
import {NgOtpInputModule} from "ng-otp-input"
import {OtpService} from "./services/otp.service"
import {DeviceDetectorService} from 'ngx-device-detector';
import { OtpComponent } from './otp/otp.component';
// the second parameter 'fr' is optional
registerLocaleData(localeVi, "vi");
registerLocaleData(localeEn, "en");

const reducers = {
  auth: authReducer,
  period: periodReducer,
  packages: packagesReducer,
  historyExamed: historyExamedReducer,
  history: historyReducer,
  patient: patientReducer,
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    CustomerRegisterComponent,
    SectionBookingHomeComponent,
    SectionBookingMbComponent,
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    HeaderComponent,
    LanguagesComponent,
    LoginFormComponent,
    FooterComponent,
    LoggedPageComponent,
    OverviewsComponent,
    UserGuidsComponent,
    GuildDetailComponent,
    AppoinmentComponent,
    SkeletonComponent,
    BookingComponent,
    DatePickerComponent,
    FormPeriodComponent,
    PatientInfomationComponent,
    MedicalHistoryComponent,
    DashboardHeaderComponent,
    PatientFormComponent,
    ResultMecialComponent,
    PatientResultComponent,
    LastHCResultComponent,
    MyBookingComponent,
    BookingBaseComponent,
    BookingDateComponent,
    BookingDoctorComponent,
    BookingHomeComponent,
    BookingMobileComponent,
    BookingPhoneNumberComponent,
    BookingSpecialtyComponent,
    BookingTimeComponent,
    DoctorListItemComponent,

    SafeHtmlPipe,

    ChangePasswordComponent,

    LogoutBtnComponent,

    FromDateToDateComponent,

    CounterAnimComponent,

    SelectMedicalHistoryComponent,

    FormConfirmExamComponent,

    BtnUploadAppointmentComponent,

    BtnCancelAppointmentComponent,

    RegisterComponent,

    BtnExportHcComponent,

    ChangePasswordModalComponent,

    RenderPDFComponent,

    PopupPostComponent,

    BaoMatComponent,

    HCComponent,
    SuccessComponent,
    OtpComponent,
  ],
  imports: [
    NgOtpInputModule,
    ClickOutsideModule,
    HttpClientModule,
    DecodeHtmlEntitiesModule,
    BrowserModule.withServerTransition({appId: "serverApp"}),
    AppRoutingModule,
    SlideshowModule,
    FormsModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
      maxAge: 10,
    }),
    TransferHttpCacheModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    NgbModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    CountUpModule,
    NgxQRCodeModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: "vi"},
    BookingService,
    ClinicService,
    DoctorService,
    TranslateService,
    AuthService,
    RouteFactoryService,
    UrlService,
    CompanyService,
    PersonalService,
    GuidService,
    AihApiService,
    OtpService,
    DeviceDetectorService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
