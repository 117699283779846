import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Reducers } from "src/app/reducers";
import { Subscription, forkJoin } from "rxjs";
import { toDateFormat } from "src/app/utilities";
import { CompanyService } from "src/app/services/aih/company-api.service";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";

@Component({
  selector: "app-btn-export-hc",
  templateUrl: "./btn-export-hc.component.html",
  styleUrls: ["./btn-export-hc.component.scss"],
})
export class BtnExportHcComponent implements OnInit, OnDestroy {
  constructor(
    protected companyService: CompanyService,
    protected translate: TranslateService,
    @Inject(Store) protected store: Store<Reducers>
  ) {}

  subscire: Subscription;
  package_code: string;
  fromDate: string;
  toDate: string;
  url: string | null = null;

  ngOnInit() {
    this.subscire = this.store.pipe(select("period")).subscribe((response) => {
      if (response) {
        const { period, fromDate, toDate } = response;
        if (period) {
          this.package_code = period.package_code;
          this.fromDate = fromDate ? toDateFormat(fromDate) : "";
          this.toDate = toDate ? toDateFormat(toDate) : "";
          // this.getURL().then(url => {
          //   this.url = url
          // }).catch(() => {});
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscire.unsubscribe();
  }

  getURL(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.companyService
        .exportAppointment(
          environment.key,
          this.fromDate,
          this.toDate,
          this.package_code
        )
        .subscribe((response: string) => {
          if (response === "Error") {
            return reject();
          } else {
            const url = environment.aih_api + "/Reports/" + response;
            return resolve(url);
          }
        });
    });
  }

  onClick() {
    this.companyService
      .exportAppointment(
        environment.key,
        this.fromDate,
        this.toDate,
        this.package_code
      )
      .subscribe((response: string) => {
        if (response === "Error") {
          forkJoin([this.translate.get("Error")]).subscribe(([error]) => {
            Swal.fire(error, "", "error");
          });
        } else {
          const url =  environment.aih_api + "/Reports/" + response;
          this.delay(100).then(() => {
            Swal.fire("success","exported successfully","success");
            window.open(url, "_blank");
          });
          //window.location.href = url;
          //
          // window.location.href = url;
        }
      });
    return false;
  }

  async delay(ms: number) {
    await new Promise((resolve) => setTimeout(() => resolve(), ms)).then(() =>
      console.log("fired")
    );
  }
}
