import { Patient } from '../models/aih/Patient.model';
import * as moment from "moment";
import { COOKIE_PATIENT } from '../Key';
import { setCookie, getCookie } from '../helpers/cookie';

export const ADD_PATIENT = "ADD_PATIENT";
export const UPD_PATIENT = "UPD_PATIENT";
export const DEL_PATIENT = "DEL_PATIENT";

export const addPatient = (data: Patient) => ({ type: ADD_PATIENT, data });
export const updatePatient = (data: Patient) => ({ type: UPD_PATIENT, data });
export const removePatient = () => ({ type: DEL_PATIENT });


let initState: Patient | null = null;

let rawProfile = sessionStorage.getItem(COOKIE_PATIENT);

if (!rawProfile) {
    rawProfile = getCookie(COOKIE_PATIENT);
}

if (rawProfile) {
    try {
        initState = JSON.parse(rawProfile);
    } catch (e) {
        /** IGRONE */
        console.error(e);
    }
}

export function patientReducer(state: null | Patient = initState, action: any) {
    const { data } = action;
    if (data && data.date_of_birth) {
        const dob = moment(data.date_of_birth);
        if (dob.isValid()) {
            data.date_of_birth = dob.format("DD-MM-YYYY");
        }
    }
    switch (action.type) {
        case ADD_PATIENT:
            sessionStorage.setItem(COOKIE_PATIENT, JSON.stringify(action.data));
            return action.data;
        case UPD_PATIENT:
            if (action.data && !action.data.date_of_exam) {
                delete action.data.date_of_exam;
            }
            const next = { ...state, ...action.data };
            sessionStorage.setItem(COOKIE_PATIENT, JSON.stringify(next));
            return next;
        case DEL_PATIENT:
            sessionStorage.removeItem(COOKIE_PATIENT);
            setCookie(COOKIE_PATIENT, "");
            return null;
        default:
            return state;
    }
}
