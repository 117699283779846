import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-booking-mb',
  templateUrl: './section-booking-mb.component.html',
  styleUrls: ['./section-booking-mb.component.scss']
})
export class SectionBookingMbComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
