import { Component, OnInit, Inject, Input, EventEmitter, Output } from '@angular/core';
import { RestApiService } from 'src/app/services/rest-api.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-bao-mat',
  templateUrl: './bao-mat.component.html',
  styleUrls: ['./bao-mat.component.scss']
})
export class BaoMatComponent implements OnInit {

  @Output() onAccept = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Input() showCancelButton = false;
  @Input() auto = false;
  @Input() type = "webapp_pagesecurity";

  constructor(
    private translate: TranslateService,
    @Inject(RestApiService) public restApi: RestApiService
  ) { }

  ngOnInit() {
    if (this.auto) {
      this.getPost();
    }
  }

  getPost() {
    this.restApi.post("post/detail-top", {
      "post_type": this.type,
      "lang": this.translate.currentLang === "en" ? "en-US" : "vi-VN"
    }).subscribe((response: any) => {
      if (response) {
        forkJoin([
          this.translate.get('Agree'),
          this.translate.get('Disagree')
        ]).subscribe(([ok, cancel]: string[]) => {
          console.log(ok, cancel);
          console.log(this.showCancelButton);

          Swal.fire({
            title: response.Post.post_name,
            html: response.Post.post_longdesc,
            confirmButtonText: ok,
            showConfirmButton: true,
            showCancelButton: this.showCancelButton,
            cancelButtonText: cancel,
            customClass: {
              container: "popup-content-post",
              content: "text-left justify-content-start"
            },
          }).then(result => {
            if (result && result.value === true) {
              return this.onAccept.emit(true);
            }
            this.onCancel.emit(false);
          }).catch(() => this.onCancel.emit(false));
        })
      }
    });
  }

}
