import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Unsubscribable } from 'rxjs';
import { CompanyService } from 'src/app/services/aih/company-api.service';

@Component({
  selector: 'app-btn-upload-appointment',
  templateUrl: './btn-upload-appointment.component.html',
  styleUrls: ['./btn-upload-appointment.component.scss']
})
export class BtnUploadAppointmentComponent implements OnInit, OnDestroy {

  @Output() onUploadSuccess = new EventEmitter();

  constructor(
    protected translateService: TranslateService,
    protected companyService: CompanyService
  ) { }
  file: any;

  translate = {
    "Are you sure?": "Are you sure?",
    "Success": "Success",
    "Cancel": "Cancel",
    "Please click here to view detail": "Please click here to view detail",
    "Error": "Error",
  };

  unsubscire: Unsubscribable;

  ngOnInit() {
    const data = Array.from(Object.keys(this.translate));
    this.unsubscire = this.translateService.get(data).subscribe(transate => {
      this.translate = transate;
    })
  }

  ngOnDestroy() {
    this.unsubscire.unsubscribe();
  }

  handleChangeFile(files: FileList) {
    if (!files.length) {
      return;
    }
    const file = files[0];
    Swal.fire({
      title: this.translate["Are you sure?"],
      showCancelButton: true,
      showConfirmButton: true
    }).then(({value}) => {
      if (value === true) {
        this.companyService.uploadApointment(file).subscribe(response => {
          this.file = "";
          if (response === "OK") {
            if(this.onUploadSuccess){
              this.onUploadSuccess.emit();
            }
            Swal.fire({
              title: this.translate["Success"],
              icon: "success"
            })
          } else if(response === "Error"){
            Swal.fire({
              title: this.translate["Error"],
              icon: "error"
            })
          }else {
            const url = `http://aqua.aih.com.vn:8181/Reports/${response}`;
            Swal.fire({
              title: this.translate["Error"],
              html: `<a href="${url}" download>${this.translate["Please click here to view detail"]}</a>`
            }).then(() => {
              window.location.href = url;
            });
          }
        });
      }
    })
  }

}
