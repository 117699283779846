import {
  Component,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
} from "@angular/core";
// import * as jsPDF from "jspdf";
// import * as html2canvas from "html2canvas";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { Store, select } from "@ngrx/store";
import { Reducers } from "src/app/reducers";
import { Patient } from "src/app/models/aih/Patient.model";
import * as moment from "moment";
import { dateOfBirth } from "src/app/shared/dob";
// import { ModalDirective } from "ngx-bootstrap";

@Component({
  selector: "app-hc",
  templateUrl: "./hc.component.html",
  styleUrls: ["./hc.component.scss"],
})
export class HCComponent implements OnInit, OnChanges, OnDestroy {
  // @ViewChild("largeModal", { static: false }) largeModal: ModalDirective;
  isSubmiting = false;

  frontInternalMedicine = [
    [
      { name: "Tiểu đường", checked: true },
      { name: "Cao huyết áp", checked: true },
      { name: "Cholesterol cao", checked: true },
      { name: "Bệnh lý tuyến giáp", checked: false },
      { name: "Bướu giáp", checked: false },
      { name: "Ung thư", checked: false },
    ],
    [
      { name: "Bệnh bạch cầu", checked: false },
      { name: "Vảy nến", checked: false },
      { name: "Đau ngực", checked: false },
      { name: "Bệnh tim", checked: false },
      { name: "Âm thổi tim", checked: false },
      { name: "Đau hoặc loét dạ dày", checked: false },
    ],
    [
      { name: "Thuyên tắc phổi", checked: false },
      { name: "Suyễn", checked: false },
      { name: "Tràn khí màng phổi", checked: false },
      { name: "Đột quỵ", checked: false },
      { name: "Động kinh", checked: false },
    ],
    [
      { name: "Cườm", checked: false },
      { name: "Bệnh thận", checked: false },
      { name: "Sỏi thận", checked: false },
      { name: "Bệnh Crohn", checked: false },
      { name: "Viêm đại tràng", checked: false },
    ],
    [
      { name: "Thiếu máu", checked: false },
      { name: "Viêm gan (Loại …..)", checked: false },
      { name: "Sốt thấp khớp", checked: false },
      { name: "Viêm phổi", checked: false },
      { name: "Lao", checked: false },
    ],
  ];

  livingHabits = [
    { slug: "hut_thuoc", name: "Hút thuốc", status: false, mime: "điếu" },
    {
      slug: "uong_bia",
      sub_name: "Bia",
      name: "Bia",
      status: false,
      mime: "chai",
    },
    {
      slug: "uong_ruu",
      hide: true,
      sub_name: "Rượu",
      status: false,
      mime: "ly",
    },
    {
      slug: "uong_ruu_manh",
      hide: true,
      sub_name: "Rượu mạnh",
      status: false,
      mime: "oz",
    },
    { slug: "caphe", name: "Cà phê", status: false, mime: "tách" },
    { slug: "tra", name: "Trà", status: false, mime: "tách" },
    {
      slug: "tap_the_duc",
      name: "Tập thể dục",
      status: false,
      mime: "lần",
      day: "lần",
      month: "lần",
      other: " ",
    },
  ];

  gynecological = [
    {
      slug: "pk1",
      name: "Bạn đã mang thai bao nhiêu lần ?",
      status: false,
      value: "",
    },
    {
      slug: "pk2",
      name: "Bạn đã bỏ thai hoặc sẩy thai bao nhiêu lần ?",
      status: false,
      value: "",
    },
    { slug: "pk3", name: "Bạn có bao nhiêu con ?", status: false, value: "" },
    {
      slug: "pk4",
      name: "Bạn đang sử dụng phương pháp ngừa thai nào ?",
      status: false,
      value: "",
    },
    {
      slug: "pk5",
      name: "Bạn đã từng có bệnh lý phụ khoa không ?",
      status: false,
      value: "",
    },
    {
      slug: "pk6",
      name: "Ngày đầu tiên của kỳ kinh gần nhất",
      status: false,
      value: "",
    },
    {
      slug: "pk7",
      name: "Ngày xét nghiệm tầm soát ung thư cổ tử cung gần nhất:",
      status: false,
      value: "",
    },
  ];

  vaccins = [
    { code: "bcg", name: "Bcg" },
    { code: "diphtheria", name: "Diphtheria" },
    { code: "tetanus", name: "Tetanus" },
    { code: "polio", name: "Polio" },
    { code: "hepatitisa", name: "Hepatitis A" },
    { code: "hepatitisb", name: "Hepatitis B" },
    { code: "typhoid_fever", name: "Typhoid Fever" },
    { code: "rabies", name: "Rabies" },
  ];

  @Input() data: any;
  @Input() disabledBtnSave: boolean;
  @Output() onSave = new EventEmitter();

  public lang: string;
  public subscribeLang: Subscription;
  public subscribePatient: Subscription;
  public patient: Patient;
  public isPrinting: boolean;
  public isShowForm: boolean;
  public _date = new Date();

  constructor(
    protected translateService: TranslateService,
    public store: Store<Reducers>
  ) {}

  ngOnInit() {
    this.lang = this.translateService.getDefaultLang();
    this.subscribeLang = this.translateService.onLangChange.subscribe(
      ({ lang }) => {
        this.lang = lang;
      }
    );
    this.subscribePatient = this.store
      .pipe(
        select((state) => ({
          patient: state.patient,
        }))
      )
      .subscribe(({ patient }) => {
        if (patient && patient.patient_visit_id) {
          this.patient = patient;
          // console.log(patient.date_of_birth, this.dateOfBirth(patient.date_of_birth));
          //console.log(this.patient);
          // console.log(this.data);
          let _tmpChanged = [];
          let _i = 0;
          for (let i of this.gynecological) {
            _i += 1;
            _tmpChanged.push({
              slug: i.slug,
              name: i.name,
              status: this.data.phu_khoa["pk" + _i] == "" ? true : false,
              value: this.data.phu_khoa["pk" + _i],
            });
            //console.log(_tmpChanged);
            //_i+=1;
          }
          this.gynecological = _tmpChanged;
          //console.log(this.gynecological);
        }
      });
  }

  ngOnDestroy() {
    this.subscribeLang.unsubscribe();
    this.subscribePatient.unsubscribe();
  }

  // ngOnChanges({ data: { currentValue } }: SimpleChanges) {
  //   console.log('Test',currentValue);
  // }

  ngOnChanges(data: SimpleChanges) {
    if (data.currentValue != data.previousValue) {
      // let _tmpChanged = [];
      // let _i = 0;
      // for (let i of this.gynecological) {
      //   _i += 1;
      //   _tmpChanged.push({
      //     slug: i.slug,
      //     name: i.name,
      //     status: this.data.phu_khoa["pk" + _i] == "" ? true : false,
      //     value: this.data.phu_khoa["pk" + _i],
      //   });
      //   //console.log(_tmpChanged);
      //   //_i+=1;
      // }
      // this.gynecological = _tmpChanged;
      console.log('Changed', data.currentValue);
    }else{
      console.log('No change',data.currentValue);
    }
  }

  waitingShowForm() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 2000);
    });
  }

  onSubmit() {
    this.onSave.emit();
  }

  dateOfBirth(date: string) {
    return dateOfBirth(date);
  }

  toDate(date: string) {
    return date ? moment(date).format("L") : "";
  }

  // print() {
  //   this.isPrinting = true;
  //   this.isShowForm = true;
  //   this.waitingShowForm().then(() => {
  //     const canvasHTML: any = html2canvas;
  //     var source = window.document.getElementById("print-pdf2");
  //     // console.log($("#print-pdf2"));
  //     canvasHTML(source, {
  //       width: $("#print-pdf2").width() + 20,
  //       height: $("#print-pdf2").height() + 15,
  //       scrollX: 0,
  //       scrollY: window.pageYOffset * -1
  //     }).then((canvas: any) => {
  //       var img = canvas.toDataURL("image/jpeg");
  //       // $("#img").attr("src", img);
  //       var doc = new jsPDF("p", "mm", "a4");
  //       var width = doc.internal.pageSize.getWidth();
  //       var height = doc.internal.pageSize.getHeight();
  //       // doc.addPage();
  //       // doc.addImage(img, 'JPEG', 0, 0, width, height);

  //       var imgWidth = 210;
  //       var pageHeight = 295;
  //       var imgHeight = canvas.height * imgWidth / canvas.width;
  //       var heightLeft = imgHeight;

  //       var doc = new jsPDF('p', 'mm');
  //       var position = 0;

  //       doc.addImage(img, 'PNG', 2, position, imgWidth, imgHeight - 3);
  //       heightLeft -= pageHeight;

  //       while (heightLeft >= 0) {
  //         position = heightLeft - imgHeight - 3;
  //         doc.addPage();
  //         doc.addImage(img, 'PNG', 2, position, imgWidth, imgHeight);
  //         heightLeft -= pageHeight;
  //       }

  //       doc.save(`${new Date().getTime()}.pdf`);
  //       this.isPrinting = false;
  //       this.isShowForm = false;
  //     }).catch((error) => {
  //       console.error(error);
  //       this.isPrinting = false;
  //       this.isShowForm = false;
  //       alert("have error when printing.");
  //     });
  //   });
  // }

  print() {
    // console.log("print");
    this.isShowForm = true;
    this.delay(100).then((any) => {
      let divToPrint = document.getElementById("divToPrint").innerHTML;
      let newWindow = window.open(
        "",
        "_blank",
        "top=0,left=0,height=100%,width=auto"
      );
      newWindow.document.open();
      newWindow.document.write(`
        <html>
            <head>
              <title>Print tab</title>
              <link href="../../assets/css/tables-print.css" rel="stylesheet" type="text/css" />
              <link href="../../assets/css/form-print.scss" rel="stylesheet" type="text/css" />
              <style>
              </style>
            </head>
            <body onload="window.print();window.close()">${divToPrint}
            </body>
          </html>
        `);

      newWindow.document.close();
      this.isShowForm = false;
    });
  }

  async delay(ms: number) {
    await new Promise((resolve) => setTimeout(() => resolve(), ms)).then(() =>
      console.log("fired")
    );
  }

  getPhuKhoa(slug: string) {
    let _tmpChanged = [];
    let _i = 0;
    for (let i of this.gynecological) {
      _i += 1;
      _tmpChanged.push({
        slug: i.slug,
        name: i.name,
        status: this.data.phu_khoa["pk" + _i] == "" ? true : false,
        value: this.data.phu_khoa["pk" + _i],
      });

      //_i+=1;
    }
    this.gynecological = _tmpChanged;
    // console.log(_tmpChanged);
    return this.data && this.data.phu_khoa[slug];
  }
}
