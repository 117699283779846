import {Component, OnInit, Inject, Output, EventEmitter} from '@angular/core';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {Store, select} from '@ngrx/store';
import {Reducers} from '../../reducers';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  isValid = true;
  isLoading = false;
  username: string;

  @Output() success = new EventEmitter();

  constructor(
    protected authService: AuthService,
    @Inject(Router) protected router: Router,
    protected translate: TranslateService,
    @Inject(Store) protected store: Store<Reducers>
  ) {
  }

  ngOnInit() {
    this.store.pipe(select('auth')).subscribe((auth: any) => {
      if (auth && auth.hasOwnProperty('user_phone')) {
        this.username = auth.user_phone;
      }
    });
  }

  onSubmit($form: NgForm) {
    forkJoin([
      this.translate.get('please check again'),
      this.translate.get('confirm password not correct'),
      this.translate.get('Success'),
      this.translate.get('Your password has been changed successfully'),
      this.translate.get('Error occurred'),
      this.translate.get('Your phone number or password is incorrect'),
      this.translate.get('required_fields')
    ]).subscribe(([check, confirm, success, successTxt, error, msg, required_fields]) => {
      if (!$form.valid) {
        return Swal.fire(error, required_fields, 'warning');
      }
      const {value: {account, new_password, password, confirm_password}} = $form;
      if (new_password !== confirm_password) {
        return Swal.fire(check, confirm, 'question');
      }
      this.authService.changePassword(account, password, new_password).subscribe(({StatusCode}: any) => {
        if (StatusCode === 1) {
          if (!this.username) {
            this.router.navigate(['/']);
          }
          this.success.emit();
          return Swal.fire(success, successTxt, 'success');
        } else {
          return Swal.fire(error, msg, 'error');
        }
      });
    });
  }

}
